import React from 'react';
import KnockoutBrackets, { KnockoutPhase } from './KnockoutBrackets';
import { useSystemState } from '../SystemState';
import { getKnockoutMatch, getPhases, getKnockoutTeam } from '../utils/utils';
import { MatchPhase } from '../classes/match';
import { Box } from '@mui/material';
import Progress from './Progress';

interface RankingKnockoutProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const supportedTeams = [8, 16];

export default function RankingKnockout(props: RankingKnockoutProps) {
    const state = useSystemState();
    const [knockoutPhases, setKnockoutPhases] = React.useState<KnockoutPhase[]>([]);
    const [ loading, setLoading ] = React.useState<boolean>(false);

    React.useEffect(() => {
        async function getInfo() {
          try {
            setLoading(true);
            const teams = await state.teams;
            const matches = await state.matches;
            const knockout = await state.knockout;

            if(!knockout.isValid()) {
                return;
            }

            const knockoutTeams = knockout.getItems().map(k => {
                return {
                    placeholder: k.placeholder,
                    team: teams.getById(k.team)
                }
            })

            if(knockoutTeams.length === 0) {
              return;
            }

            if(knockoutTeams.length !in supportedTeams) {
              throw new Error(`Number of teams not supported: ${knockoutTeams.length}`);
            }
        
            const phases = getPhases(knockoutTeams.length);

            const knockoutPhases : KnockoutPhase[] = phases.map(p => {
              return {
                phase: p,
                matches: []
              }
            })
        
            // filling first phase
            for(let i=0; i<knockoutTeams.length; i+=2) {
              const team1 = knockoutTeams[i];
              const team2 = knockoutTeams[i+1];
              const match = getKnockoutMatch(team1, team2, matches);

              knockoutPhases[0].matches.push({
                  team1: team1,
                  team2: team2,
                  match: match
              })
            }

            for(let i=1; i<knockoutPhases.length; i++) {
              const currentPhase = knockoutPhases[i];
              const previousPhase = knockoutPhases[i-1];

              // fill phase matches
              for(let j=0; j<previousPhase.matches.length; j+=2) {
                const match1 = previousPhase.matches[j];
                const match2 = previousPhase.matches[j+1];

                const team1 = getKnockoutTeam(match1);
                const team2 = getKnockoutTeam(match2);
                const match = getKnockoutMatch(team1, team2, matches);

                currentPhase.matches.push({
                  team1: team1,
                  team2: team2,
                  match: match
                })

                if(currentPhase.phase === MatchPhase.FINAL_1_2) {
                  const losingTeam1 = getKnockoutTeam(match1, true);
                  const losingTeam2 = getKnockoutTeam(match2, true);
                  const match = getKnockoutMatch(losingTeam1, losingTeam2, matches);

                  currentPhase.matches.push({
                    team1: losingTeam1,
                    team2: losingTeam2,
                    match: match
                  })
                }
              }
            }

            setKnockoutPhases(knockoutPhases);
          } catch(err: any) {
            console.error(err);
          } finally {
            setLoading(false);
          }
        }
    
        getInfo();
    }, [state]);

    return (
        <div>
        {(
          <Box sx={{ py: 4 }}>
            { loading && <Progress /> }
            <KnockoutBrackets phases={knockoutPhases} />
          </Box>
        )}
      </div>
    );
}