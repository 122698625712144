import { Box, Chip, Container } from "@mui/material";
import { Match, MatchPhase } from "../classes/match";
import { getMatchPhase } from "../utils/utils";
import { Teams } from "../classes/team";
import MatchListGrid from "./MatchListGrid";
import React from "react";

export type MatchDetails = {
  match: Match,
  goals?: number
}

interface MatchListProps {
    matches: Match[];
    teams: Teams;
    goals?: number[];
    px?: number,
    showDate?: boolean
}

function groupMatchesByPhase(matches: Match[], teams: Teams, goals?: number[]) {
    const map = new Map<string, MatchDetails[]>();
  
    matches.forEach((m, index) => {
      const phase = m.phase;
      let key = "";
  
      if(phase === MatchPhase.GROUP) {
        const team = teams.getById(m.team1); 
        key = team ? team.group : MatchPhase.GROUP;
      } else {
        key = phase;
      }

      const md = {
        match: m,
        goals: goals?.at(index)
      }
  
      map.get(key)?.push(md) ?? map.set(key, [md]);
    });
    return map;
}

export default function MatchList(props: MatchListProps) {
    const { matches, teams, showDate, goals, px } = props;
    matches.sort(Match.sort);
    const groups = groupMatchesByPhase(matches, teams, goals);

    return (
      <React.Fragment>
        <Container sx={{ px: px ?? 2, py: 2 }}>
        {
          Array.from(groups.keys()).map(group => {
            const groupMatches = groups.get(group);
            const phaseStr = getMatchPhase(group).toLocaleUpperCase();

            if(groupMatches === undefined) {
                console.error(`Error: no matches found for group ${group}`);
                return (<React.Fragment />);
            }

            return (
                <Box key={group} sx={{mb: 4}}>
                    <Chip size="small" label={phaseStr} sx={{mb: 2, typography:"overline"}} />
                    <MatchListGrid matches={groupMatches} teams={teams} showDate={showDate} />
                </Box>
            )    
            })
        }
        </Container>
      </React.Fragment>
    )
}