import { Card, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import React from 'react';
import { getCountdown, toDoubleDigitString } from '../utils/utils';
import { DateTime } from 'luxon';

interface CountdownProps {
    deadline: DateTime;
    text: string;
}
  
export default function Countdown(props: CountdownProps) {
    const { deadline, text } = props;
    const [, ss, mm, hh, dd] = getCountdown(deadline);
    const [seconds, setSeconds] = React.useState<number>(ss);
    const [minutes, setMinutes] = React.useState<number>(mm);
    const [hours, setHours] = React.useState<number>(hh);
    const [days, setDays] = React.useState<number>(dd);

    React.useEffect(() => {
        const x = setInterval(() => {
            const [distance, ss, mm, hh, dd] = getCountdown(deadline);
            setSeconds(ss);
            setMinutes(mm);
            setHours(hh);
            setDays(dd);

        if (distance < 0) {
            clearInterval(x);
        }
        }, 1000)
    }, [deadline]);

    return (
        <Card variant='outlined' sx={{ px: 4, py: 2, textAlign: "center", display: "inline-block", borderRadius: 2 }}>
            <Typography variant="h4" gutterBottom>
                {text.toLocaleUpperCase()}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={1}>
                <Grid xs={3}>
                    <Typography variant="h4">
                        {toDoubleDigitString(days)}
                    </Typography>
                </Grid>
                <Grid xs={3}>
                    <Typography variant="h4">
                        {toDoubleDigitString(hours)}
                    </Typography>
                </Grid>
                <Grid xs={3}>
                    <Typography variant="h4">
                        {toDoubleDigitString(minutes)}
                    </Typography>
                </Grid>
                <Grid xs={3}>
                    <Typography variant="h4">
                        {toDoubleDigitString(seconds)}
                    </Typography>
                </Grid>
                <Grid xs={3}>
                    <Typography variant="overline">
                        GG
                    </Typography>
                </Grid>
                <Grid xs={3}>
                    <Typography variant="overline">
                        ORE
                    </Typography>
                </Grid>
                <Grid xs={3}>
                    <Typography variant="overline">
                        MIN
                    </Typography>
                </Grid>
                <Grid xs={3}>
                    <Typography variant="overline">
                        SEC
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
  }