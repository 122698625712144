import { Alert, AlertColor, Box, Button, Container, Grid, TextField } from '@mui/material';
import React from 'react';
import { checkToken, deleteToken, storeToken } from '../utils/api';
import LockIcon from '@mui/icons-material/Lock';
import LoadingBackdrop from '../components/LoadingBackdrop';

type AlertInfo = {
    severity: AlertColor,
    msg: string
  }  

export default function AdminPage() {
    const [ token, setToken ] = React.useState<string>("");
    const [ alert, setAlert ] = React.useState<AlertInfo | undefined>();
    const [ sendReq, setSendReq ] = React.useState<boolean>(false);

    React.useEffect(() => {
        async function checkAndStoreToken(token: string) {
            try {
                const response = await checkToken(token);
                if(response) {
                    storeToken(token);
                    setAlert({severity: "success", msg: "Token salvato"});
                } else {
                    deleteToken();
                    setAlert({severity: "error", msg: "Token non corretto"});
                }
            } catch(err) {
                setAlert({severity: "error", msg: `${err}`});
            } finally {
                setSendReq(false);
            }
        }

        if(sendReq) {
            checkAndStoreToken(token);
        }
    }, [sendReq, token]);

    return (
        <Container sx={{ px: 0, py: 4, height: "80vh" }}>
            <LoadingBackdrop open={sendReq} />
            { alert ?
                <Alert
                    sx={{ position: "absolute", top: 100, width: "90%", left: "5%" }}
                    severity={alert.severity}
                    onClose={() => {setAlert(undefined)}}
                    >
                    {alert.msg}
                </Alert> : <></>
            }
            <Box sx={{ height: "100%", alignContent: "center" }}>
                <Grid container spacing={3} textAlign="center">
                    <Grid item xs={12}>
                        <TextField
                            label="Password"
                            variant="standard"
                            type='password'
                            defaultValue={token}
                            sx={{width: "80%" }}
                            inputProps={{ style: { textAlign: "center" } }}
                            size="medium"
                            onChange={(e) => {
                                setToken(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                            variant="contained" 
                            startIcon={<LockIcon />}
                            sx={{ my: 4, mx: 2 }} 
                            onClick={() => {
                                setAlert(undefined);

                                if(token.length === 0) {
                                    setAlert({ severity: "error", msg: "Token non valido" });
                                    return;
                                }

                                setSendReq(true);
                            }}
                        >
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}