import { Card, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSystemState } from '../SystemState';
import React from 'react';
import { TeamRanking } from '../classes/teamRanking';
import { useNavigate } from 'react-router-dom';
import { MatchPhase } from '../classes/match';
import useYear from '../hooks/useYear';
import Progress from './Progress';

interface RankingProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function createData(
    team: string,
    link: string,
    played: number,
    wins: number,
    draws: number,
    losses: number,
    plusminus: string,
    difference: string,
    points: number,
  ) {
    return { team, link, played, wins, draws, losses, plusminus, difference, points };
  }

export default function RankingGroup(props: RankingProps) {
    const year = useYear();
    const state = useSystemState();
    const [ rankings, setRankings ] = React.useState<TeamRanking[]>([]);
    const [ groups, setGroups ] = React.useState<string[]>([]);
    const navigate = useNavigate();
    const [ loading, setLoading ] = React.useState<boolean>(false);

    React.useEffect(() => {
        async function getRankings() {
            try {
                setLoading(true);
                const teams = await state.teams;
                const matches = await state.matches;

                const teamRankings = teams.getItems().map(t => new TeamRanking(t))
                matches.getCompleted().filter(m => m.phase === MatchPhase.GROUP).forEach(m => {
                    const homeTeam = teamRankings.find(t => t.team._id === m.team1);
                    const awayTeam = teamRankings.find(t => t.team._id === m.team2);

                    if(homeTeam === undefined || awayTeam === undefined) {
                        console.error(`Error! couldn't find all teams in match ${m._id}`);
                        return;
                    }

                    if(m.goals1 > m.goals2) {
                        homeTeam.wins++;
                        awayTeam.losses++;
                    } else if(m.goals2 > m.goals1) {
                        homeTeam.losses++;
                        awayTeam.wins++;
                    } else {
                        homeTeam.draws++;
                        awayTeam.draws++;
                    }

                    homeTeam.goalsScored += m.goals1;
                    homeTeam.goalsConceded += m.goals2;
                    awayTeam.goalsScored += m.goals2;
                    awayTeam.goalsConceded += m.goals1;
                })

                // get number of groups from the teams
                state.teams.then(teams => {
                    const groups = new Set<string>();
                    teams.getItems().forEach(t => groups.add(t.group));
                    const groupArray = Array.from(groups);
                    groupArray.sort();
                    setGroups(groupArray);
                }).catch((err) => {
                    console.error(err);
                })

                // get rankings
                teamRankings.sort(TeamRanking.sort);
                setRankings(teamRankings);
            } catch(err: any) {
                console.error(err);
            } finally {
              setLoading(false);
            }
        }

        getRankings();
      }, [state]);


    const groupCards = groups.map(g => {
        const rows = rankings.filter(r => r.team.group === g).map(r => {
            return createData(
                r.team.name,
                r.team.url(),
                r.getPlayed(),
                r.wins,
                r.draws,
                r.losses,
                `${r.goalsScored}-${r.goalsConceded}`,
                r.getGoalDifferenceStr(),
                r.getPoints()
            )
        });      
        
        return (
            <TableContainer key={g} component={Card} sx={{my:2, p: 2, borderRadius: 5}}>
            <Table sx={{}} aria-label="ranking" size='small'>
              <TableHead>
                <TableRow sx={{borderBottom: 0}}>
                    <TableCell align="center" sx={{px: 0}}></TableCell>
                    <TableCell sx={{px: 0, fontWeight: 'bold'}}>Gruppo {g}</TableCell>
                    <TableCell align="center" sx={{px: 0}}>G</TableCell>
                    <TableCell align="center" sx={{px: 0}}>V</TableCell>
                    <TableCell align="center" sx={{px: 0}}>N</TableCell>
                    <TableCell align="center" sx={{px: 0}}>P</TableCell>
                    <TableCell align="center" sx={{px: 0}}>+/-</TableCell>
                    <TableCell align="center" sx={{px: 0}}>DR</TableCell>
                    <TableCell align="center" sx={{px: 0}}>Pt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={row.team}
                    sx={{ 'td, th': { border: 0, py: 1.5 }, cursor:"pointer" }}
                    hover={true}
                    onClick={() => {navigate(`/${year}/${row.link}`)}}
                  >
                    <TableCell align="center" sx={{pl:0, pr:1}}>{index + 1}</TableCell>
                    <TableCell component="th" scope="row" sx={{p: 0, whiteSpace: 'nowrap',  overflow:"hidden", textOverflow: "ellipsis", maxWidth:100}}>
                      {row.team}
                    </TableCell>
                    <TableCell align="center" sx={{pl:1, pr:1}}>{row.played}</TableCell>
                    <TableCell align="center" sx={{pl:1, pr:0.5}}>{row.wins}</TableCell>
                    <TableCell align="center" sx={{pl:0.5, pr:0.5}}>{row.draws}</TableCell>
                    <TableCell align="center" sx={{pl:0.5, pr:1}}>{row.losses}</TableCell>
                    <TableCell align="center" sx={{pl:1, pr:1}}>{row.plusminus}</TableCell>
                    <TableCell align="center" sx={{pl:1, pr:1}}>{row.difference}</TableCell>
                    <TableCell align="center" sx={{pl:1, pr:1}}>{row.points}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
    })

    return (
        <div>
        {(
          <React.Fragment>
            { loading && <Progress /> }
            <Container sx={{ p: 1 }}>
                { groupCards }
            </Container>
          </React.Fragment>
        )}
      </div>
    );
}