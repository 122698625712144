import { SingleNews } from "../classes/news";
import { Card, CardActionArea, CardMedia, CardContent, Typography } from "@mui/material";
import { getPicsFolder, getUrl, matchDayString } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import useYear from "../hooks/useYear";

interface NewsCardProps {
    news: SingleNews
}

export default function NewsCard(props: NewsCardProps) {
    const { news } = props;
    const navigate = useNavigate();
    const year = useYear();

    const picSrc = news.pic.length > 0 ? `${getPicsFolder(year)}/${news.pic}` : undefined;

    return (
    <Card raised sx={{ width: 300 }}>
        <CardActionArea onClick={() => navigate(getUrl(year, news))}>
            {
                picSrc && 
                <CardMedia
                    component="img"
                    height="140"
                    image={picSrc}
                />
            }
            <CardContent>
            <Typography gutterBottom variant="h5">
                {news.title}
            </Typography>
            <Typography sx={{ mb: 1.5 }} variant="body2" color="text.secondary" textTransform="capitalize">
                {matchDayString(news.date, false)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {news.summary}
            </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
    )
}