import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSystemState } from '../SystemState';
import { Team, Teams } from '../classes/team';
import { Match } from '../classes/match';
import { Player } from '../classes/player';
import MatchList from '../components/MatchList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ElementList from '../components/ElementList';
import { getPicsFolder, getUrl, parseInteger } from '../utils/utils';
import AvatarTitle from '../components/AvatarTitle';
import { PlayerAvatar, TeamAvatar } from '../components/CustomAvatar';
import useYear from '../hooks/useYear';
import Progress from '../components/Progress';

const avatarSize = 60;

export default function TeamDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const year = useYear();
  const state = useSystemState();
  const [ team, setTeam ] = React.useState<Team>();
  const [ teams, setTeams ] = React.useState<Teams>();
  const [ matches, setMatches ] = React.useState<Match[]>([]);
  const [ players, setPlayers ] = React.useState<Player[]>([]);
  const [ loading, setLoading ] = React.useState<boolean>(false);
  
  React.useEffect(() => {
    async function fillTeamInfo() {
      try {
        setLoading(true);
        const teamId = parseInteger(params.id ?? "");

        if(Number.isNaN(teamId)) {
          return;
        }

        const teams = await state.teams;
        const team = teams.getById(teamId);

        if(team === undefined) {
          return;
        }

        setTeams(teams);
        setTeam(team);

        const matches = await state.matches;
        setMatches(matches.getByTeam(teamId));

        const players = (await state.players).getByTeam(teamId);
        players.sort(Player.sortByJersey);
        setPlayers(players);
      } catch(err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    fillTeamInfo();
  }, [state, navigate, params.id]);

  const teamName = team?.name ?? "Team";

  return (
    <React.Fragment>
      { loading && <Progress /> }
    <Container sx={{ p:1 }} >
    {
      team !== undefined &&
      <AvatarTitle 
        avatar={{
          title: teamName,
          avatar: <TeamAvatar team={team} size={avatarSize} />
        }} 
      />
    }

    { team?.pic && <img alt="foto di squadra" className="team-img" src={`${getPicsFolder(year)}/${team.pic}`} /> }


    {
      players.length > 0 &&
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="team-players-content"
          >
            <Typography>Squadra</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ElementList divider
              elements={
                players.map(p => {
                  return {
                    title: p.fullName(),
                    url: getUrl(year, p),
                    avatar: <PlayerAvatar player={p} />,
                    icon: p.jerseyString()
                  }
                })
              }
            />
          </AccordionDetails>
        </Accordion>
    }
    
    {
      matches.length > 0 && teams !== undefined && 
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="team-matches-content"
          >
            <Typography>Partite</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MatchList matches={matches} teams={teams} px={0} showDate />
          </AccordionDetails>
        </Accordion>
    }
    </Container>
    </React.Fragment>
  );
}