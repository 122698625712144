import { Match } from "../classes/match";
import { Teams } from "../classes/team";
import MatchList from "./MatchList";

interface MatchListProps {
    matches: Match[];
    teams: Teams;
    index: number;
    value: number;
}

export default function MatchListTab(props: MatchListProps) {
    const { matches, teams } = props;

    return (
      <div>
      { <MatchList matches={matches} teams={teams} /> }
      </div>
    )
}