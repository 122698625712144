import { useNavigate, useParams } from "react-router-dom";
import { useSystemState } from "../SystemState";
import { SingleNews } from "../classes/news";
import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { downloadTextFile } from "../utils/csv";
import { getNewsFolder, getPicsFolder, matchDayString, parseInteger } from "../utils/utils";
import Markdown from "react-markdown";
import useYear from "../hooks/useYear";
import Progress from "../components/Progress";

export default function NewsPage() {
    const params = useParams();
    const navigate = useNavigate();
    const year = useYear();
    const state = useSystemState();
    const [ news, setNews ] = React.useState<SingleNews>();
    const [ newsText, setNewsText ] = React.useState<string>("");
    const [ loading, setLoading ] = React.useState<boolean>(false);

    React.useEffect(() => {
        async function getNews() {
            try {
                setLoading(true);
                const allNews = await state.news;
                const news = allNews.getById(parseInteger(params.id ?? ""));

                if(news === undefined) {
                    return;
                }

                setNews(news);

                const newsPath = `${getNewsFolder(year)}/${news.file}`;
                const text = await downloadTextFile(newsPath);
                setNewsText(text);
            } catch(err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        getNews();
    }, [state, navigate, params.id, year]);

    const picsFolder = getPicsFolder(year);
    const picSrc = news && news.pic.length > 0 ? `${picsFolder}/${news.pic}` : undefined;

    return (
        <Container sx={{ pb: 4 }}>
        { loading && <Progress /> }
        { news && 
            <>
            <Box sx={{ p: 2 }}>
                <Typography variant="h2" textAlign="center">{news.title}</Typography>
                <Typography variant="h6" textAlign="center" sx={{py: 2}} textTransform="capitalize">{matchDayString(news.date, false)}</Typography>
                { picSrc && <img alt={news.title} src={picSrc} width="100%" /> }
            </Box>
            <Markdown className="markdown" urlTransform={(url) => `${picsFolder}/${url}`}>
                {newsText}
            </Markdown>
            </>
        }
        </Container>
    )
}