import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { SystemStateProvider } from './SystemState';
import Layout from './layout/Layout';
import NoPage from './pages/NoPage';
import MainPage from './pages/MainPage'
import Rankings from './pages/Rankings';
import { DefaultYear, Years } from './config';
import TeamList from './pages/TeamList';
import TeamDetails from './pages/TeamDetails';
import Matches from './pages/Matches';
import MatchDetails from './pages/MatchDetails';
import PlayerDetails from './pages/PlayerDetails';
import NewsPage from './pages/NewsPage';
import AdminPage from './pages/AdminPage';

export default function App() {
  return (
    <BrowserRouter>
      <SystemStateProvider>
        <Routes>
          {Years.map((text) => {
            const path = `${text}/`
            return (
              <Route key={text} path={path} element={<Layout />}>
                <Route index element={<MainPage />} />
                <Route path="rankings" element={<Rankings />} />
                <Route path="rankings/:page" element={<Rankings />} />
                <Route path="teams" element={<TeamList />} />
                <Route path="team/:id" element={<TeamDetails />} />
                <Route path="matches" element={<Matches />} />
                <Route path="matches/:page" element={<Matches />} />
                <Route path="match/:id" element={<MatchDetails />} />
                <Route path="player/:id" element={<PlayerDetails />} />
                <Route path="news/:id" element={<NewsPage />} />
                <Route path="admin" element={<AdminPage />} />
                <Route path="*" element={<NoPage />} />
              </Route>
            )
          })}
          <Route path="admin" element={<Navigate to={`/${DefaultYear}/admin`} replace={true} />} />
          <Route path="/" element={<Navigate to={DefaultYear} replace={true} />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </SystemStateProvider>
    </BrowserRouter>
  );
}