import { Typography } from "@mui/material";
import { Match, MatchPhase } from "../classes/match";
import { Variant } from "@mui/material/styles/createTypography";
import { Team } from "../classes/team";

interface MatchTeamProps {
    match: Match,
    team?: Team,
    variant?: Variant,
    placeholder?: string,
}

export default function MatchTeam(props: MatchTeamProps) {
    const {match, team, variant, placeholder} = props;
    const teamSx = match.isComplete() && team
        && match.phase !== MatchPhase.GROUP
        && match.getKnockoutWinner() !== team._id ? {
        color: "text.secondary",
        //textDecoration: "line-through"
    } : {}

    return (
        <Typography variant={variant} gutterBottom sx={teamSx}>
            {team ? team.name : placeholder}
        </Typography>
    )
}