import { Box, Button, Grid, Typography } from "@mui/material";
import { Info } from "../classes/info";
import { dayString } from "../utils/utils";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InstagramIcon from '@mui/icons-material/Instagram';
import useYear from "../hooks/useYear";

interface InfoGridProps {
    width: number,
    info: Info
}

export default function InfoGrid(props: InfoGridProps) {
    const { width, info } = props;
    const year = useYear();
    
    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Grid container spacing={3} sx={{ py: 2, width: width }}>
                <Grid item xs={2} textAlign="center">
                    <LocationOnIcon sx={{ my: -0.7 }} />
                </Grid>
                <Grid item xs={10} textAlign="center">
                    <Typography variant="button">Oulx, Piazza Garambois</Typography>
                </Grid>
                <Grid item xs={2} textAlign="center">
                    <CalendarMonthIcon sx={{ my: -0.6 }} />
                </Grid>
                <Grid item xs={10} textAlign="center">
                    <Typography variant="button">{dayString(info.start_date, false)} - {dayString(info.end_date, false)} {year}</Typography>
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <Button target="blank" href="https://instagram.com/_u/street_soccer_oulx/" startIcon={<InstagramIcon />} variant="contained" sx={{textTransform: "none"}}>
                        @street_soccer_oulx
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}