import { TextField, Typography } from "@mui/material";
import { Match } from "../classes/match";
import { dateToString, dayString } from "../utils/utils";
import { Variant } from "@mui/material/styles/createTypography";

interface MatchDateProps {
    match: Match,
    variant?: Variant,
    edit?: boolean,
    onChange?: (value: string) => void,
}

export default function MatchDate(props: MatchDateProps) {
    const {match, variant, onChange, edit} = props;

    return (
        <>
        {
            edit ? 
            <TextField
                variant="standard"
                defaultValue={dateToString(match.date)}
                inputProps={{ style: { textAlign: "center" } }}
                size="medium"
                onChange={e => onChange && onChange(e.target.value)}
            />
            :
            !match.isComplete() ?
            <Typography variant={variant}>
            {dayString(match.date, false, true)}
            </Typography>
            : <></>
        }
        </>
    )
}