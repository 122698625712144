import * as React from 'react';
import { Button, Chip, Container, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSystemState } from '../SystemState';
import { Team } from '../classes/team';
import { Match, MatchPhase } from '../classes/match';
import { TeamAvatar } from '../components/CustomAvatar';
import MatchResult from '../components/MatchResult';
import { dateToString, getMatchPhase, isDateValid, matchTimeString, parseInteger, reload } from '../utils/utils';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import GoalList, { MatchGoals } from '../components/GoalList';
import useYear from '../hooks/useYear';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { isAdmin, loadToken, sendApiRequest } from '../utils/api';
import LoadingBackdrop from '../components/LoadingBackdrop';
import AlertDialog, { AlertInfo, setErrorAlert, setSuccessAlert } from '../components/AlertDialog';
import Progress from '../components/Progress';
import MatchDate from '../components/MatchDate';
import { DateTime } from 'luxon';
import FloatingButton from '../components/FloatingButton';
import FloatingButtonBar from '../layout/FloatingButtonBar';

type MatchInfo = {
  match: Match,
  teamHome: Team,
  teamAway: Team
}

type EditInfo = {
  matchId: number,
  date: string,
  time: string,
  goalsHome: string,
  goalsAway: string,
  goalsMap: Map<number, string>
}

type EditJsonData = {
  token: string,
  year: string,
  match_id: number,
  date: string,
  time: string,
  home_goals: string,
  away_goals: string,
  penalty_winner?: string,
  goals_map: { [p: number]: number}
}

const avatarSize = 50;

function getPenaltyWinnerName(matchInfo: MatchInfo) {
  switch(matchInfo.match.penaltyWinner) {
    case matchInfo.teamHome._id: return matchInfo.teamHome.shortName;
    case matchInfo.teamAway._id: return matchInfo.teamAway.shortName;
    default: throw new Error(`Something is wrong, penalty winner is not a valid team: ${matchInfo.match.penaltyWinner}`);
  }
}

function resetEditInfo(editInfo: EditInfo, match: Match) {
  editInfo.matchId = match._id;
  editInfo.date = dateToString(match.date);
  editInfo.time = matchTimeString(match.date);
  editInfo.goalsHome = `${Number.isNaN(match.goals1) ? "" : match.goals1}`;
  editInfo.goalsAway = `${Number.isNaN(match.goals2) ? "" : match.goals2}`;
  editInfo.goalsMap.clear();
}

function save(
  matchInfo: MatchInfo,
  editInfo: EditInfo,
  penaltyWinner: number | undefined,
  year: string,
  setAlert: React.Dispatch<React.SetStateAction<AlertInfo | undefined>>,
  setJsonPayload: React.Dispatch<React.SetStateAction<EditJsonData | undefined>>,
) {
  const token = loadToken();
  if(token === null) {
    setErrorAlert(`Non sei admin!`, setAlert);
    return true;
  }

  const matchId = editInfo.matchId;

  if(Number.isNaN(matchId)) {
    setErrorAlert(`Match ID non valido: ${editInfo.matchId}`, setAlert);
    return false;
  }

  const date = editInfo.date.replaceAll("/", "-");
  const time = editInfo.time.replaceAll(".",":");

  if(!isDateValid(DateTime.fromISO(`${date}T${time}`))) {
    setErrorAlert(`Data e ora non validi`, setAlert);
    return false;
  }

  const noResult = editInfo.goalsHome.length === 0 && editInfo.goalsAway.length === 0;
  const validResult = editInfo.goalsHome.length !== 0 && editInfo.goalsAway.length !== 0;

  if(noResult) {
    if(penaltyWinner) {
      setErrorAlert(`Indicato vincente ai rigori ma nessun risultato inserito`, setAlert);
      return false;
    }

  } else if(validResult) {
    const homeGoals = parseInteger(editInfo.goalsHome ?? "");
    const awayGoals = parseInteger(editInfo.goalsAway ?? "");

    if(Number.isNaN(homeGoals + awayGoals) || homeGoals < 0 || awayGoals < 0) {
      setErrorAlert(`Risultato non valido`, setAlert);
      return false;
    }
  
    if(penaltyWinner && homeGoals !== awayGoals) {
      setErrorAlert(`Indicato vincente ai rigori ma il match non è terminato in pareggio`, setAlert);
      return false;
    }

    if(matchInfo.match.phase !== MatchPhase.GROUP && homeGoals === awayGoals && !penaltyWinner) {
      setErrorAlert(`Match terminato in pareggio ma vincente ai rigori non indicato`, setAlert);
      return false;
    }
  } else {
    setErrorAlert(`Risultato non valido`, setAlert);
    return false;
  }

  const data : EditJsonData = {
    token: token,
    year: year,
    match_id: matchId,
    date: date,
    time: time,
    home_goals: editInfo.goalsHome,
    away_goals: editInfo.goalsAway,
    penalty_winner: penaltyWinner ? `${penaltyWinner}` : undefined,
    goals_map: {}
  }

  try {
    editInfo.goalsMap.forEach((g, p) => {
      const goals = g.length === 0 ? 0 : parseInteger(g);
  
      if(Number.isNaN(goals) || goals < 0) {
        throw new Error(`Numero di goal non valido`);
      }

      if(noResult && goals > 0) {
        throw new Error(`Nessun risultato inserito ma inseriti goal`);
      }
  
      data.goals_map[p] = goals;
    })
  } catch(err: any) {
    setErrorAlert(`${err}`, setAlert);
    return false;
  }

  setJsonPayload(data);
  return true;
}

async function sendUpdate(
  jsonPayload: EditJsonData,
  setJsonPayload: React.Dispatch<React.SetStateAction<EditJsonData | undefined>>,
  setAlert: React.Dispatch<React.SetStateAction<AlertInfo | undefined>>,
) {
  try {
    const response = await sendApiRequest("street-update", JSON.stringify(jsonPayload));

    if(response.code === 200) {
      setSuccessAlert("Modifiche salvate.", setAlert);
      setTimeout(() => reload(), 500
    );
    } else {
      setErrorAlert(`${response.msg}`, setAlert);
    }
  } catch(err) {
      setErrorAlert(`${err}`, setAlert);
  } finally {
      setJsonPayload(undefined);
  }
}

export default function MatchDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const year = useYear();
  const state = useSystemState();
  const [ matchInfo, setMatchInfo ] = React.useState<MatchInfo>();
  const [ goals, setGoals ] = React.useState<MatchGoals[]>();
  const [ edit, setEdit ] = React.useState<boolean>(false);
  const [alert, setAlert] = React.useState<AlertInfo>();
  const [ editInfo, setEditInfo ] = React.useState<EditInfo>();
  const [ penaltyWinner, setPenaltyWinner ] = React.useState<number>();
  const [ jsonPayload, setJsonPayload ] = React.useState<EditJsonData>();
  const [ loading, setLoading ] = React.useState<boolean>(false);

  const editEnabled = isAdmin();

  React.useEffect(() => {
    async function fillMatchInfo() {
      try {
        setLoading(true);
        const matches = await state.matches;
        const teams = await state.teams;
        const match = matches.getById(parseInteger(params.id ?? ""));

        if(match === undefined) {
          return;
        }

        const teamHome = teams.getById(match.team1);
        const teamAway = teams.getById(match.team2);

        if(teamHome === undefined || teamAway === undefined) {
          throw new Error("Couldn't find teams");
        }

        setMatchInfo({
          match: match,
          teamHome: teamHome,
          teamAway: teamAway
        })

        if(!Number.isNaN(match.penaltyWinner)) setPenaltyWinner(match.penaltyWinner);

        const editInfo: EditInfo = { 
          matchId: 0,
          date: "",
          time: "",
          goalsHome: "",
          goalsAway: "",
          goalsMap: new Map()
        };
        resetEditInfo(editInfo, match);
        setEditInfo(editInfo);

        const players = await state.players;
        const goals = await state.goals;

        setGoals(
          players.getItems()
            .filter(p => [teamHome._id, teamAway._id].includes(p.team))
            .map(p => {
              return {
                player: p,
                goals: goals.getByPlayerAndMatch(p._id, match._id)?.goals ?? 0
              }
            })
        )
      } catch(err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    fillMatchInfo();
  }, [state, navigate, params.id]);

  React.useEffect(() => {
    if(jsonPayload !== undefined) {
      sendUpdate(jsonPayload, setJsonPayload, setAlert);
    }
  }, [jsonPayload, year]);


  return (
    <React.Fragment>
    { loading && <Progress /> }
    { alert && <AlertDialog alert={alert} onClose={() => setAlert(undefined)} />}
    <Container sx={{ textAlign: 'center' }}>
      <LoadingBackdrop open={jsonPayload !== undefined} />
      {
        matchInfo && editInfo &&
          <React.Fragment>
            <Chip label={getMatchPhase(matchInfo.match.phase)} />
            <Grid container spacing={1}
              justifyContent="center" 
              alignItems="center"
              sx={{pt: 4, pb:2}}
            >
              <Grid item xs={4} textAlign="center">
                <TeamAvatar team={matchInfo.teamHome} size={avatarSize} link center />
              </Grid>
              <Grid item xs={4} textAlign="center">
                <MatchResult 
                  onHomeChange={(value) => editInfo.goalsHome = value}
                  onAwayChange={(value) => editInfo.goalsAway = value}
                  onTimeChange={(value) => editInfo.time = value}
                  edit={edit}
                  match={matchInfo.match}
                  variant='h3' 
                />
              </Grid>
              <Grid item xs={4} textAlign="center">
                <TeamAvatar team={matchInfo.teamAway} size={avatarSize} link center />
              </Grid>
              <Grid item xs={4} textAlign="center">
                <Typography variant="body2"  sx={{py: 2}}  fontWeight="bold">
                  {matchInfo.teamHome.name}
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="center">
                <MatchDate
                  onChange={(value) => editInfo.date = value}
                  edit={edit}
                  match={matchInfo.match}
                  variant='overline'
                />
                {
                  !edit && matchInfo.match.isComplete() && matchInfo.match.penalties() &&
                  <Typography variant="caption" fontWeight="bold">
                  {getPenaltyWinnerName(matchInfo)} vince ai rigori
                  </Typography>
                }
              </Grid>
              <Grid item xs={4} textAlign="center">
                <Typography variant="body2" sx={{py: 2}}  fontWeight="bold">
                  {matchInfo.teamAway.name}
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="center">
              {
                edit && matchInfo.match.phase !== MatchPhase.GROUP &&
                <FormControlLabel 
                  control={
                    <Switch 
                      checked={penaltyWinner === matchInfo.teamHome._id}
                      onChange={(e, checked) => checked ? setPenaltyWinner(matchInfo.teamHome._id) : setPenaltyWinner(undefined)} 
                    />
                  } 
                  label={<Typography variant="body2">Vincente ai rigori</Typography>}
                />
              }
              </Grid>
              <Grid item xs={4} textAlign="center"></Grid>
              <Grid item xs={4} textAlign="center">
              {
                edit && matchInfo.match.phase !== MatchPhase.GROUP &&
                <FormControlLabel 
                  control={
                    <Switch 
                      checked={penaltyWinner === matchInfo.teamAway._id}
                      onChange={(e, checked) => checked ? setPenaltyWinner(matchInfo.teamAway._id) : setPenaltyWinner(undefined)} 
                    />
                  } 
                  label={<Typography variant="body2">Vincente ai rigori</Typography>}
                />
              }
              </Grid>
            </Grid>
          </React.Fragment>
      }

      {
        matchInfo && goals && editInfo &&
          <Grid container spacing={1} columns={20}
          >
            <Grid item xs={9}>
              <GoalList 
                goals={goals.filter(g => g.player.team === matchInfo.teamHome._id)}
                sx={{ textAlign:"left", m: 0, p: 0 }}
                edit={edit}
                onChange={(player, value) => editInfo.goalsMap.set(player, value)}
              />
            </Grid>
            <Grid item xs={2} textAlign="center">
              <SportsSoccerIcon  />
            </Grid>
            <Grid item xs={9} textAlign="center">
              <GoalList 
                goals={goals.filter(g => g.player.team === matchInfo.teamAway._id)}
                sx={{ textAlign:"right", m: 0, p: 0 }}
                edit={edit}
                onChange={(player, value) => editInfo.goalsMap.set(player, value)}
              />
            </Grid>
          </Grid>
      }

      {
        matchInfo && goals && editInfo && editEnabled ? edit ? 
          <>
            <Button 
              variant="contained" 
              startIcon={<SaveIcon />}
              sx={{ my: 4, mx: 2 }} 
              onClick={() => {
                if(save(matchInfo, editInfo, penaltyWinner, year, setAlert, setJsonPayload)) {
                  resetEditInfo(editInfo, matchInfo.match);
                  setPenaltyWinner(matchInfo.match.penaltyWinner);
                  setEdit(false);
                }
              }}
            >
              Salva
            </Button>
            <Button 
              variant="contained" 
              startIcon={<CloseIcon />}
              sx={{ my: 4, mx: 2 }} 
              onClick={() => {
                setAlert(undefined);
                setPenaltyWinner(matchInfo.match.penaltyWinner);
                setEdit(false);
              }}
            >
              Annulla
            </Button>
          </>
          :
          <FloatingButtonBar>
            <FloatingButton 
                  action="edit"
                  onClick={() => {
                    setAlert(undefined);
                    setEdit(true);
                  }}
              />
          </FloatingButtonBar>
          : <></>
      }
    </Container>
    </React.Fragment>
  );
}