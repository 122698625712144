import { parseInteger } from "../utils/utils";
import { DBItem, ItemManager } from "./dbItem";

export class Player implements DBItem {
    _id: number;
    name: string;
    surname: string;
    team: number;
    jersey: number;
    pic: string;

    constructor(csvArray: string[]) {
        if(csvArray.length !== 6) {
            throw new Error(`Invalid CSV array length for Player: ${csvArray.length}`);
        }

        const id = parseInteger(csvArray[0]);

        if(Number.isNaN(id)) {
            throw new Error(`Invalid ID for player: ${csvArray}`);
        }

        const team = parseInteger(csvArray[3]);

        if(Number.isNaN(team)) {
            throw new Error(`Invalid team for player ${id}`);
        }

        this._id = id;
        this.name = csvArray[1];
        this.surname = csvArray[2];
        this.team = team;
        this.jersey = parseInteger(csvArray[4]);
        this.pic = csvArray[5];
    }

    url(): string {
        return `player/${this._id}`;
    }

    id(): string {
        return `player-${this._id}`;
    }

    fullName() : string {
        return `${this.name} ${this.surname}`;
    }

    shortName() : string {
        return `${this.surname} ${this.name.substring(0,1)}.`;
    }

    initials() : string {
        return `${this.name.substring(0,1)}${this.surname.substring(0,1)}`.toLocaleUpperCase();
    }

    jerseyString() : string {
        return Number.isNaN(this.jersey) ? "" : `${this.jersey}`;
    }

    hasJersey() : boolean {
        return !Number.isNaN(this.jersey);
    }

    static sortByName(a: Player, b: Player): number {
        const nameSort = a.name.localeCompare(b.name);
        const surnameSort = a.surname.localeCompare(b.surname);

        return surnameSort === 0 ? nameSort : surnameSort;
    }

    static sortByJersey(a: Player, b: Player): number {
        const jerseyA = a.hasJersey() ? a.jersey : Number.MAX_VALUE;
        const jerseyB = b.hasJersey() ? b.jersey : Number.MAX_VALUE;
        const jerseySort = jerseyA - jerseyB;
        return (jerseySort === 0) ? Player.sortByName(a, b) : jerseySort;
    }
}

export class Players implements ItemManager<Player> {
    private items: Player[] = [];

    addItem(line: string[]) {
        const player = new Player(line);
        this.items.push(player);
    }
    
    getItems(): Player[] {
        return this.items;
    }

    getById(id: number): Player | undefined {
        return this.items.find(item => item._id === id);
    }

    getByTeam(teamId: number): Player[] {
        return this.getItems().filter(p => p.team === teamId);
    }
}