import React, { ReactNode } from "react";
import { List, ListItem, ListItemButton, ListItemAvatar, ListItemText, Divider, ListItemIcon, Typography, SxProps } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { useNavigate } from "react-router-dom";

export type ListElement = {
    title: string,
    subtitle?: string,
    url?: string,
    avatar?: ReactNode,
    icon?: ReactNode,
    titleVariant?: Variant,
    subtitleVariant?: Variant,
    titleSx?: SxProps
}

interface ElementListProps {
    elements: ListElement[],
    divider?: boolean,
    disablePadding?: boolean
}

export default function ElementList(props: ElementListProps) {
  const { elements, divider, disablePadding } = props;
  const navigate = useNavigate();

  return (
      <List disablePadding={disablePadding}>
      { elements.length > 0 &&
        elements.map((el, index) => {
          const item =
            <>
              {
                el.avatar &&
                <ListItemAvatar>
                  {el.avatar}
                </ListItemAvatar>
              }
              <ListItemText
                primary={<Typography variant={el.titleVariant ?? "body1"}>{el.title}</Typography>}
                secondary={<Typography variant={el.subtitleVariant ?? "body2"} sx={{color:"text.secondary"}}>{el.subtitle}</Typography>}
                sx={el.titleSx}
              />
              {
                el.icon && 
                <ListItemIcon sx={{ justifyContent: 'center' }} >
                  {el.icon}
                </ListItemIcon>
              }
            </>

          return (
            <ListItem key={index} disableGutters>
              {
                el.url === undefined ? 
                  <>{item}</> : 
                  <ListItemButton onClick={() => el.url && navigate(el.url)}>{item}</ListItemButton>
              }
            </ListItem>
          )
        }).reduce((prev, next) => {
          return (
            <>
              {prev}
              { divider && <Divider component="li" /> }
              {next}
            </>
          )
        })
      }
    </List>
  )
}