import { DateTime } from "luxon";
import { dateToString, isDateValid, parseInteger } from "../utils/utils";
import { DBItem, ItemManager } from "./dbItem";

export enum MatchPhase {
    UNKNOWN = "unknown",
    GROUP = "group",
    ROUND_16 = "round16",
    QUARTER_FINALS = "quarter",
    SEMI_FINALS = "semi",
    FINAL_3_4 = "final34",
    FINAL_1_2 = "final12"
}

export class Match implements DBItem {
    _id: number;
    team1: number;
    team2: number;
    date: DateTime;
    phase: MatchPhase;
    goals1: number;
    goals2: number;
    penaltyWinner: number;
    placeholder1: string;
    placeholder2: string;

    constructor(csvArray: string[]) {
        if(csvArray.length !== 11) {
            throw new Error(`Invalid CSV array length for Match: ${csvArray.length}`);
        }

        const id = parseInteger(csvArray[0]);

        if(Number.isNaN(id)) {
            throw new Error(`Invalid ID for match: ${csvArray}`);
        }

        const matchPhase = Object.values(MatchPhase).find(p => csvArray[5] === p);

        if(matchPhase === undefined) {
            throw new Error(`Match phase not valid: ${csvArray[5]}`)
        }

        const dateStr = `${csvArray[3].replaceAll("/", "-")}T${csvArray[4].replaceAll(".",":")}`;
        const date = DateTime.fromISO(dateStr);

        if(!isDateValid(date)) {
            throw new Error(`Invalid date: ${dateStr}`)
        }

        this._id = id;
        this.team1 = parseInteger(csvArray[1]);
        this.team2 = parseInteger(csvArray[2]);
        this.date = date;
        this.phase = matchPhase;
        this.goals1 = parseInteger(csvArray[6]);
        this.goals2 = parseInteger(csvArray[7]);
        this.penaltyWinner = parseInteger(csvArray[8]);
        this.placeholder1 = csvArray[9];
        this.placeholder2 = csvArray[10];

        if(!Number.isNaN(this.penaltyWinner) && ![this.team1, this.team2].includes(this.penaltyWinner)) {
            throw new Error(`Penalty winner not one of the two teams: ${this.penaltyWinner}`);
        }
    }

    url(): string {
        return `match/${this._id}`;
    }

    id(): string {
        return `match-${this._id}`;
    }

    isValid(): boolean {
        return !Number.isNaN(this.team1) 
            && !Number.isNaN(this.team2) 
    }

    penalties(): boolean {
        return this.phase !== MatchPhase.GROUP && this.goals1 === this.goals2;
    }

    isComplete(): boolean {
        return this.isValid()
            && !Number.isNaN(this.goals1) 
            && !Number.isNaN(this.goals2)
            && (this.penalties() ? !Number.isNaN(this.penaltyWinner) : true)
    }

    getMatchday() : string {
        return dateToString(this.date)
    }

    getKnockoutWinner() : number {
        return this.goals1 === this.goals2 ? this.penaltyWinner :
            this.goals1 > this.goals2 ? this.team1 : this.team2
    }

    static sort(a: Match, b: Match) {
        const dateA = a.date.hour < 8 ? a.date.plus({ days: 1}) : a.date;
        const dateB = b.date.hour < 8 ? b.date.plus({ days: 1}) : b.date;
        return dateA.diff(dateB).milliseconds;
    }
}

export class Matches implements ItemManager<Match> {
    private items: Match[] = [];

    addItem(line: string[]) {
        const match = new Match(line);
        this.items.push(match);
    }
    
    getItems(): Match[] {
        return this.items;
    }

    getValid(): Match[] {
        return this.items.filter(match => match.isValid());
    }

    getCompleted(): Match[] {
        return this.items.filter(match => match.isComplete());
    }

    getById(id: number): Match | undefined {
        return this.items.find(item => item._id === id);
    }

    getByTeam(teamId: number) : Match[] {
        return this.getValid().filter(m => m.team1 === teamId || m.team2 === teamId);
    }

    findKnockoutMatch(team1: number, team2: number) {
        return this.getValid().find(m => m.phase !== "group" && m.team1 === team1 && m.team2 === team2);
    }
}