import Box from '@mui/material/Box';
import { ReactNode } from 'react';


interface FloatingButtonBarProps {
    children?: ReactNode
}

export default function FloatingButtonBar({ children }: FloatingButtonBarProps) {

    return (
    <Box 
        gap={300}
        sx={{
            position: "fixed",
            bottom: 75,
            right: 20,
            zIndex: 10,
        }}
    >
        { children }
    </Box>
    );
}