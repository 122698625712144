import React from "react";
import { Team } from "../classes/team";
import { Match, MatchPhase } from "../classes/match";
import KnockoutMatchCard from "./KnockoutMatchCard";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { Box, useTheme } from "@mui/material";

export type KnockoutTeam = {
    placeholder: string,
    team?: Team
}

export type KnockoutMatch = {
    team1: KnockoutTeam,
    team2: KnockoutTeam,
    match?: Match
}

export type KnockoutPhase = {
    phase: MatchPhase,
    matches: KnockoutMatch[]
}

interface KnockoutBracketsProps {
    phases: KnockoutPhase[]
}

type Coordinates = {
    x: number,
    y: number
}

type Segment = {
    src: Coordinates,
    dst: Coordinates,
    elbow?: Coordinates
}

type CommonData = {
    segments: Segment[],
    upperInterCoords: Coordinates[],
    lowerInterCoords: Coordinates[],
    cardWidth: number,
    cardHeight: number,
    cardSpacing: number,
    width: number,
    rows: number
}

const maxWidth = 768;
const maxCardSize = 100; // should be less than maxWidth / 4 * 0.9

function drawSegments(intraCoords: Coordinates[], interCoords: Coordinates[], upper: boolean, commonData: CommonData) {
    const { segments, cardHeight, cardSpacing } = commonData;

    // if interCoords is not empty => we need to draw a segment to connect phases
    if(interCoords.length > 0 && interCoords.length !== intraCoords.length) {
        throw new Error(`Something is wrong: interCoords is ${interCoords.length} but intraCoords is ${intraCoords.length}`);
    }

    for(let i=0; i<interCoords.length; i++) {
        let src = interCoords[i];
        let dst = intraCoords[i];

        segments.push({ src: src, dst: dst});
    }

    // clear interCoords
    interCoords.length = 0;

    // if intraCoords length is 1, we just store the interCoords point
    if(intraCoords.length === 1) {
        let src = intraCoords[0];

        if(upper) {
            interCoords.push({ x: src.x, y: src.y + cardHeight / 2});
        } else {
            interCoords.push({ x: src.x, y: src.y - cardHeight / 2});
        }

        return;
    }

    // now, if intraCoords length is > 1, we draw elbow segments
    for(let i=0; i<intraCoords.length; i+=2) {
        let src = intraCoords[i];
        let dst = intraCoords[i+1];

        let y = src.y;
        let xMiddle = src.x / 2 + dst.x / 2;
        let yMiddle = upper ? src.y + cardHeight / 2 + cardSpacing / 2 : src.y - cardHeight / 2 - cardSpacing / 2;

        let middlePoint = { x: xMiddle, y: yMiddle };

        segments.push({ 
            src: { x: src.x, y: y},
            elbow: { x: src.x, y: yMiddle},
            dst: middlePoint
        });

        segments.push({ 
            src: { x: dst.x, y: y },
            elbow: { x: dst.x, y: yMiddle},
            dst: middlePoint
        });

        interCoords.push(middlePoint);
    }
}

function drawFinalSegments(
    segments: Segment[],
    upperSemifinal: Coordinates,
    lowerSemifinal: Coordinates,
    x: number,
    y: number,
    commonData: CommonData
) {
    const { cardHeight, cardWidth } = commonData;

    const card34 = { x: x, y: y };
    const card12 = { x: x * 3, y: y };
    
    segments.push({
        src: { x: upperSemifinal.x + cardWidth / 2, y: upperSemifinal.y - cardHeight / 2 },
        dst: { x: card12.x, y: card12.y },
        elbow: { x: card12.x, y: upperSemifinal.y - cardHeight / 2 }
    })

    segments.push({
        src: { x: lowerSemifinal.x + cardWidth / 2, y: lowerSemifinal.y + cardHeight / 2 },
        dst: { x: card12.x, y: card12.y + cardHeight },
        elbow: { x: card12.x, y: lowerSemifinal.y + cardHeight / 2 }
    })

    segments.push({
        src: { x: upperSemifinal.x - cardWidth / 2, y: upperSemifinal.y - cardHeight / 2 },
        dst: { x: card34.x, y: card34.y },
        elbow: { x: card34.x, y: upperSemifinal.y - cardHeight / 2 },
    })

    segments.push({
        src: { x: lowerSemifinal.x - cardWidth / 2, y: lowerSemifinal.y + cardHeight / 2 },
        dst: { x: card34.x, y: card34.y + cardHeight},
        elbow: { x: card34.x, y: lowerSemifinal.y + cardHeight / 2 },
    })
}

function drawMatchCard(
    m: KnockoutMatch,
    key: string,
    index: number,
    xBase: number,
    yBase: number,
    coordinates: Coordinates[],
    commonData: CommonData
) {
    const { cardWidth, cardHeight } = commonData;
    const multiplier = 2 * index + 1;
    const x = xBase * multiplier - cardWidth / 2;
    const y = yBase;

    coordinates.push({ x: x + cardWidth / 2, y: y + cardHeight / 2 });

    return (
        <KnockoutMatchCard 
            key={key}
            match={m}
            width={cardWidth}
            height={cardHeight}
            x={x}
            y={y}
        />
    )
}

function drawEliminationPhase(
    phase: KnockoutPhase,
    index: number,
    commonData: CommonData
) {
    const { 
        width, cardHeight, cardSpacing, rows, 
        upperInterCoords, lowerInterCoords
    } = commonData;

    const half = phase.matches.length / 2;
    const xBase = width / (phase.matches.length);
    const yUpper = (cardHeight + cardSpacing) * index;
    const yLower = (cardHeight + cardSpacing) * (rows - index - 1);

    const upperHalf = phase.matches.slice(0, half);
    const lowerHalf = phase.matches.slice(half, phase.matches.length);

    const upperCoordinates : Coordinates[] = [];
    const lowerCoordinates: Coordinates[] = [];

    const cards = upperHalf.map((m, matchIndex) => {
        return drawMatchCard(m,
            `upper-${index}-${matchIndex}`,
            matchIndex,
            xBase,
            yUpper,
            upperCoordinates,
            commonData
        );
    }).concat(lowerHalf.map((m, matchIndex) => {
        return drawMatchCard(m,
            `lower-${index}-${matchIndex}`,
            matchIndex,
            xBase,
            yLower,
            lowerCoordinates,
            commonData
        );
    }));

    // draw segments
    drawSegments(upperCoordinates, upperInterCoords, true, commonData);
    drawSegments(lowerCoordinates, lowerInterCoords, false, commonData);
    
    return cards;
}

function drawFinalsPhase(
    phase: KnockoutPhase,
    index: number,
    commonData: CommonData
) {
    const { 
        width, cardHeight, cardSpacing, cardWidth,
        upperInterCoords, lowerInterCoords, segments, 
    } = commonData;
    const xBase = width / 4;
    const y = (cardHeight + cardSpacing) * index;

    if(upperInterCoords.length === 1 && lowerInterCoords.length === 1) {
        drawFinalSegments(segments, upperInterCoords[0], lowerInterCoords[0], xBase, y, commonData);
    }

    const xFinal = phase.matches.length > 1 ? xBase * 3 : xBase * 2;

    return (
        <React.Fragment key="finals">
            <KnockoutMatchCard 
                key="final12"
                match={phase.matches[0]}
                width={cardWidth}
                height={cardHeight}
                x={xFinal - cardWidth / 2}
                y={y}
                chip="1/2"
            />

            {
                phase.matches.length > 1 && 
                (
                <KnockoutMatchCard 
                    key="final34"
                    match={phase.matches[1]}
                    width={cardWidth}
                    height={cardHeight}
                    x={xBase - cardWidth / 2}
                    y={y}
                    chip="3/4"
                />
                )
            }
        </React.Fragment>
    )
}

export default function KnockoutBrackets(props: KnockoutBracketsProps) {
    const { phases } = props;
    const dimensions = useWindowDimensions();
    const theme = useTheme();

    if(phases.length === 0) {
        return <></>;
    }

    const rows = phases.length * 2 - 1;
    const width = Math.min(dimensions.width, maxWidth);
    const xPadding = (dimensions.width - width) / 2;

    const columns = phases[0].matches.length / 2;
    const cardWidth = Math.min(width / columns, maxCardSize) * 0.9;
    const cardHeight = cardWidth;
    const cardSpacing = cardHeight / 3;
    const height = cardHeight * rows + cardSpacing * (rows + 1);

    const commonData : CommonData = {
        segments: [],
        upperInterCoords: [],
        lowerInterCoords: [],
        cardWidth: cardWidth,
        cardHeight: cardHeight,
        cardSpacing: cardSpacing,
        width: width,
        rows: rows
    }

    const matches = phases.map((p, phaseIndex) => {
        if(phaseIndex === phases.length - 1) {
            return drawFinalsPhase(p, phaseIndex, commonData);
        }

        return drawEliminationPhase(p, phaseIndex, commonData);
    })

    const svgObjects = (
        <svg height={height} width={width}>
        {
            commonData.segments.map((s, i) => {
                const elbow = s.elbow === undefined ? "" : `L${s.elbow.x} ${s.elbow.y}`;
                const path = `M${s.src.x} ${s.src.y} ${elbow} L${s.dst.x} ${s.dst.y}`;
                return (
                    <path key={i} d={path} fill="none" stroke={theme.palette.text.secondary} strokeWidth={2} />
                )
            })
        }
        </svg>
    )
    
    return (
        <Box sx={{ position: "relative" }}>
            <Box sx={{ position: "absolute", top: 0, left: xPadding }}>
                { matches }
                { svgObjects }
            </Box>
        </Box>
    )
}