import { Divider, Grid, Typography } from "@mui/material";
import { getUrl } from "../utils/utils";
import { Teams } from "../classes/team";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MatchDetails } from "./MatchList";
import MatchResult from "./MatchResult";
import MatchTeam from "./MatchTeam";
import useYear from "../hooks/useYear";

interface MatchListGridProps {
    matches: MatchDetails[];
    teams: Teams;
    showDate?: boolean;
}

export default function MatchListGrid(props: MatchListGridProps) {
    const { matches, teams, showDate } = props;
    const navigate = useNavigate();
    const year = useYear();

    return (
        <Grid container spacing={3} columns={13} 
            justifyContent="center" 
            alignItems="center"
        >
        {
            matches.map(md => {
                const match = md.match;
                const homeTeam = teams.getById(match.team1);
                const awayTeam = teams.getById(match.team2);
                const url = getUrl(year, match);
                const onClick = homeTeam && awayTeam ? () => {navigate(url)} : undefined;
                const sx = homeTeam && awayTeam ? { cursor:"pointer" } : undefined;
    
                return (
                    <React.Fragment key={match._id}>
                       <Grid item xs={5} textAlign="center" onClick={onClick} sx={sx}>
                            <MatchTeam match={match} team={homeTeam} placeholder={match.placeholder1} variant="body2" />
                        </Grid>
                        <Grid item xs={3} textAlign="center" onClick={onClick} sx={sx}>
                            <MatchResult match={match} variant="body2" colorTime showDate={showDate} />
                        </Grid>
                        <Grid item xs={5} textAlign="center" onClick={onClick} sx={sx}>
                            <MatchTeam match={match} team={awayTeam} placeholder={match.placeholder2}  variant="body2" />
                        </Grid>
                        { md.match.isComplete() && md.goals &&
                            <Grid item xs={13} textAlign="center" onClick={onClick} sx={sx}>
                                <Typography variant="caption" textAlign="center" fontWeight="bold">
                                    Goals: {md.goals}
                                </Typography>
                            </Grid>
                        }
                    </React.Fragment>
                )
            }).reduce((prev, next) => {
                return (
                  <>
                    {prev}
                    <Grid item xs={12}> 
                        <Divider />
                    </Grid>
                    {next}
                  </>
                )
            })
        }
        </Grid>
    )
}