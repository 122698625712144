export function stringToColor(string: string): string {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function textColor(color: string): string {
  const hex = color.replace('#', '');
  const cR = parseInt(hex.substring(0, 2), 16);
  const cG = parseInt(hex.substring(2, 4), 16);
  const cB = parseInt(hex.substring(4, 6), 16);

  const brightness = ((cR * 299) + (cG * 587) + (cB * 114)) / 1000;
  return (brightness > 125) ? '#000000' : '#ffffff';
}