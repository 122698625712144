import { Typography } from '@mui/material';
import React from 'react';
import { useSystemState } from '../SystemState';
import { PlayerRanking } from '../classes/playerRanking';
import { getUrl } from '../utils/utils';
import ElementList from './ElementList';
import { PlayerAvatar } from './CustomAvatar';
import useYear from '../hooks/useYear';
import Progress from './Progress';

interface RankingGoalsProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export default function RankingGoals(props: RankingGoalsProps) {
    const year = useYear();
    const state = useSystemState();
    const [ rankings, setRankings ] = React.useState<PlayerRanking[]>([]);
    const [ loading, setLoading ] = React.useState<boolean>(false);

    React.useEffect(() => {
      async function getRankings() {
          try {
              setLoading(true);
              const players = await state.players;
              const teams = await state.teams;
              const goals = await state.goals;

              const playerRankings = players.getItems().map(p => {
                const playerTeam = teams.getById(p.team);
                if(playerTeam === undefined) {
                  throw new Error(`Could not find team of player ${p._id}`);
                }

                return new PlayerRanking(p, playerTeam);
              })

              goals.getItems().forEach(g => {
                  const player = playerRankings.find(p => p.player._id === g.player);

                  if(player === undefined) {
                      console.error(`Error! couldn't find player in goal ${g._id}`);
                      return;
                  }

                  player.goals += g.goals;
              })

              // get rankings
              playerRankings.sort(PlayerRanking.sort);
              setRankings(playerRankings);
          } catch(err: any) {
              console.error(err);
          } finally {
              setLoading(false);
          }
      }
      
      getRankings();
    }, [state]);
    
    return (
        <div>
        {(
          <React.Fragment>
            { loading && <Progress /> }
            <ElementList divider
              elements={
                rankings.filter(r => r.goals > 0).map(r => {
                  return {
                    title: r.player.fullName(),
                    subtitle: r.team.name,
                    url: getUrl(year, r.player),
                    avatar: <PlayerAvatar player={r.player} />,
                    icon: <Typography fontWeight="bold">{r.goals}</Typography>
                  }
                })
              }
            />
          </React.Fragment>
        )}
      </div>
    );
}