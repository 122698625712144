import { API_GATEWAY } from "../config";

export type ApiResponse = {
    code: number,
    msg: string
}

export function isAdmin() {
    const token = localStorage.getItem("token");

    // not a security issue: token will be checked server-side anyway
    return token !== null;
}

export function storeToken(token: string) {
    localStorage.setItem("token", token);
}

export function loadToken() {
    return localStorage.getItem("token");
}

export function deleteToken() {
    return localStorage.removeItem("token");
}

export async function checkToken(token: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
        sendApiRequest("street-check", JSON.stringify({"token" : token }))
            .then((response) => {
                if(response.code === 200) resolve(true);
                else resolve(false);
            })
            .catch((err) => {
                reject(err);
            })
    })
}

export async function sendApiRequest(endpoint: string, data: string): Promise<ApiResponse> {
    return new Promise(async (resolve, reject) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: data,
                mode: "cors" as RequestMode
            };
            
            const response = await fetch(`${API_GATEWAY}/${endpoint}`, requestOptions);
            resolve({
                code: response.status,
                msg: await response.text()
            })
        } catch(err) {
            reject(err);
        }
    });
}