import { DateTime } from "luxon";
import { DBItem, ItemManager } from "./dbItem";
import { parseInteger } from "../utils/utils";

export class SingleNews implements DBItem {
    _id: number;
    title: string;
    summary: string;
    file: string;
    pic: string;
    date: DateTime;

    constructor(csvArray: string[]) {
        if(csvArray.length !== 6) {
            throw new Error(`Invalid CSV array length for SingleNews: ${csvArray.length}`);
        }

        this._id = parseInteger(csvArray[0]);
        this.title = csvArray[1];
        this.summary = csvArray[2];
        this.file = csvArray[3];
        this.pic = csvArray[4];
        this.date = DateTime.fromISO(csvArray[5]);
    }

    url(): string {
        return `news/${this._id}`;
    }

    id(): string {
        return `news-${this._id}`;
    }
}

export class News implements ItemManager<SingleNews> {
    private items: SingleNews[] = [];

    addItem(line: string[]) {
        const news = new SingleNews(line);
        this.items.push(news);
    }
    
    getItems(): SingleNews[] {
        return this.items;
    }

    getById(id: number): SingleNews | undefined {
        return this.items.find(item => item._id === id);
    }
}