import { Team } from "./team";

export class TeamRanking {
    team: Team;
    wins: number = 0;
    draws: number = 0;
    losses: number = 0;
    goalsScored: number = 0;
    goalsConceded: number = 0;

    constructor(
        team: Team
    ) {
        this.team = team;
    }

    getPlayed(): number {
        return this.wins + this.draws + this.losses;
    }

    getPoints(): number {
        return this.wins * 3 + this.draws;
    }

    getGoalDifference(): number {
        return this.goalsScored - this.goalsConceded;
    }

    getGoalDifferenceStr(): string {
        const diff = this.getGoalDifference();

        return diff > 0 ? `+${diff}` : diff.toString();
    }

    static sort(a: TeamRanking, b: TeamRanking): number {
        const pointsDiff = b.getPoints() - a.getPoints();
        const goalsDiff = b.getGoalDifference() - a.getGoalDifference();
        const goalsScoredDiff = b.goalsScored - a.goalsScored;
        const nameSort = Team.sort(a.team, b.team);

        return pointsDiff === 0 ? goalsDiff === 0 ? goalsScoredDiff === 0 ? nameSort : goalsScoredDiff : goalsDiff : pointsDiff
    }
}