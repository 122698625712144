import { SxProps } from "@mui/material";
import { DBItem } from "../classes/dbItem";
import { Match, Matches, MatchPhase } from "../classes/match";
import { KnockoutTeam, KnockoutMatch } from "../components/KnockoutBrackets";
import { DateTime } from "luxon";

export function reload() {
    window.location.reload();
}

export function parseInteger(value: string) {
    if(value.length === 0) {
        return NaN;
    }

    const num = Number(value);

    if(!Number.isInteger(num)) {
        return NaN;
    }

    return num;
}

export function parsePage(page: string | null, numPages: number) {
    const pageInt = page === null ? NaN : parseInteger(page);
    return !Number.isNaN(pageInt) && pageInt >= 0 && pageInt < numPages ? pageInt : undefined;
  }

export function getYearPath(year: string) {
    return `/data/${year}`;
}

export function getDbPath(year: string) {
    return `${getYearPath(year)}/db`;
}

export function getPicsFolder(year: string) {
    return `${getYearPath(year)}/pics`;
}

export function getNewsFolder(year: string) {
    return `${getYearPath(year)}/news`;
}

export function getUrl(year: string, item: DBItem) {
    return `/${year}/${item.url()}`;
}

export function getCountdown(deadline: DateTime) {
    const now = DateTime.now().toUnixInteger();
    const distance = deadline.toUnixInteger() - now;

    const days = Math.floor(distance / (60 * 60 * 24));
    const hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((distance % (60 * 60)) / (60));
    const seconds = Math.floor((distance % (60)));

    return [distance, seconds, minutes, hours, days];
}

export function isDateValid(date: DateTime) {
    return date.isValid;
}

export function setLocale(date: DateTime) {
    return date.setLocale("it");
}

export function dateToString(date: DateTime): string {
    return setLocale(date).toFormat('yyyy-MM-dd');
}

export function isToday(date: DateTime) : boolean {
    return dateToString(date) === dateToString(DateTime.now());
}

export function isTomorrow(date: DateTime) : boolean {
    return dateToString(date) === dateToString(DateTime.now().plus({ days: 1 }));
}

export function isYesterday(date: DateTime) : boolean {
    return dateToString(date) === dateToString(DateTime.now().minus({ days: 1 }));
}

export function getDay(date: DateTime) {
    return setLocale(date).weekdayLong;
}

export function getMonth(date: DateTime) {
    return setLocale(date).monthLong;
}

export function dayString(date: DateTime, year: boolean = true, shortMonth: boolean = false) {
    const month = shortMonth ? "MMM" : "MMMM"
    return year ? setLocale(date).toFormat(`d ${month} yyyy`) : setLocale(date).toFormat(`d ${month}`);
}

export function matchDayString(date: DateTime, short: boolean, year: boolean = true): string {
    const dateIt = setLocale(date);
    if(short) {
        if(isToday(date)) return "Oggi";
        else if(isTomorrow(date)) return "Domani";
        else if(isYesterday(date)) return "Ieri";
        else return `${dateIt.weekdayShort} ${date.day} ${dateIt.monthShort}`;
    } else {
        return dayString(date, year);
    }
}

export function toDoubleDigitString(val: number) {
    return val.toLocaleString(
        'it-IT', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })
}

export function matchTimeString(date: DateTime): string {
    return `${toDoubleDigitString(date.hour)}:${toDoubleDigitString(date.minute)}`;
}

export function getMatchPhase(phase: string): string {
    switch(phase) {
        case MatchPhase.UNKNOWN: return ""
        case MatchPhase.GROUP: return "Fase a gruppi"
        case MatchPhase.ROUND_16: return "Ottavi di finale"
        case MatchPhase.QUARTER_FINALS: return "Quarti di finale"
        case MatchPhase.SEMI_FINALS: return "Semifinali"
        case MatchPhase.FINAL_1_2: return "Finale 1/2 posto"
        case MatchPhase.FINAL_3_4: return "Finale 3/4 posto"
        default: return `Gruppo ${phase}`
    }
}

export function getPhases(numTeams: number): MatchPhase[] {
    if(!Number.isInteger(Math.log2(numTeams)) || numTeams > 16) {
        throw new Error(`Invalid number of teams: ${numTeams}`);
    }

    switch(numTeams) {
        case 16: return [MatchPhase.ROUND_16].concat(getPhases(8));
        case 8: return [MatchPhase.QUARTER_FINALS].concat(getPhases(4));
        case 4: return [MatchPhase.SEMI_FINALS].concat(getPhases(2));
        default: return [MatchPhase.FINAL_1_2];        
    }
}

export function getKnockoutMatch(team1: KnockoutTeam, team2: KnockoutTeam, matches: Matches) {
    return team1.team !== undefined && team2.team !== undefined ?
        matches.findKnockoutMatch(team1.team._id, team2.team._id) : undefined;
}

export function getKnockoutTeam(knockoutMatch: KnockoutMatch, findLoser: boolean = false): KnockoutTeam {
    if(knockoutMatch.match === undefined || !knockoutMatch.match.isComplete()) {
        return {
            placeholder: "TBD"
        };
    }


    const winner = knockoutMatch.match.getKnockoutWinner();
    const teamWinner = winner === knockoutMatch.team1.team?._id ? knockoutMatch.team1 :
        winner === knockoutMatch.team2.team?._id ? knockoutMatch.team2 : undefined;

    if(teamWinner === undefined) {
        throw new Error(`Could not identify winner of match ${knockoutMatch.match._id}`);
    }

    const teamLoser = teamWinner === knockoutMatch.team1 ? knockoutMatch.team2 : knockoutMatch.team1;

    return findLoser ? teamLoser : teamWinner;
}

export function getTeamStyle(match: Match, team: number, strikethrough: boolean): SxProps {
    if(!match.isComplete()) return {};

    const winner = match.getKnockoutWinner();

    if(Number.isNaN(winner)) {
        return {};
    }
    else if(winner === team) {
        return {
            fontWeight: "bold"
        }
    } else {
        return {
            textDecoration: strikethrough ? "line-through" : undefined,
            color: "text.secondary"
        }
    }
}