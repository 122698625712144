import * as React from 'react';
import { Typography } from '@mui/material';
import { useSystemState } from '../SystemState';
import { Team } from '../classes/team';
import { getUrl } from '../utils/utils';
import ElementList from '../components/ElementList';
import { TeamAvatar } from '../components/CustomAvatar';
import useYear from '../hooks/useYear';
import Progress from '../components/Progress';

export default function TeamList() {
  const year = useYear();
  const state = useSystemState();
  const [ teams, setTeams ] = React.useState<Team[]>([]);
  const [ loading, setLoading ] = React.useState<boolean>(false);

  React.useEffect(() => {
    state.teams.then(async t => {
      setLoading(true);
      const teams = t.getItems();
      teams.sort((a,b) => a.name.localeCompare(b.name));
      setTeams(teams);
      setLoading(false);
    }).catch((err) => {
      console.error(`Failed to get teams: ${err}`);
    })
  }, [state]);

  return (
    <React.Fragment>
      { loading && <Progress /> }
      <Typography variant='h4' textAlign="center">Tutte le squadre</Typography>
      <ElementList divider
        elements={
          teams.map(t => {
            return {
              title: t.name,
              url: getUrl(year, t),
              avatar: <TeamAvatar team={t} />,
            }
          })
        }
      />
    </React.Fragment>
  );
}