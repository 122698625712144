import React, { createContext, useContext } from 'react';
import { ReactNode } from 'react';
import { getDbPath, getYearPath } from './utils/utils';
import { parseCsv } from "./utils/csv";
import { Goals } from "./classes/goal";
import { Matches } from "./classes/match";
import { News } from "./classes/news";
import { Players } from "./classes/player";
import { Teams } from "./classes/team";
import { KnockoutList } from './classes/knockout';
import { Info } from './classes/info';
import useYear from './hooks/useYear';

export interface SystemDB {
  goals: Promise<Goals>;
  matches: Promise<Matches>;
  news: Promise<News>;
  players: Promise<Players>;
  teams: Promise<Teams>;
  knockout: Promise<KnockoutList>;
  info: Promise<Info>;
}

function loadDb(year: string) {
  const dbPath = getDbPath(year);

  return {
    goals: parseCsv(`${dbPath}/goals.csv`, () => new Goals()),
    matches: parseCsv(`${dbPath}/matches.csv`, () => new Matches()),
    news: parseCsv(`${dbPath}/news.csv`, () => new News()),
    players: parseCsv(`${dbPath}/players.csv`, () => new Players()),
    teams: parseCsv(`${dbPath}/teams.csv`, () => new Teams()),
    knockout: parseCsv(`${dbPath}/knockout.csv`, () => new KnockoutList()),
    info: Info.parseInfo(`${getYearPath(year)}/info.json`)
  }
}

export interface SystemState extends SystemDB {
}

export type SystemStateAction = undefined;

export function SystemStateProvider({ children }: { children: ReactNode }) {
    const year = useYear();
    const [db, setDb] = React.useState<SystemDB>(initialState);

    React.useEffect(() => {
      setDb({...loadDb(year)});
    }, [year]);
  
    return (
      <SystemStateContext.Provider value={db}>
          {children}
      </SystemStateContext.Provider>
    );
}

export function useSystemState() {
  return useContext(SystemStateContext);
}

export function useSystemStateDispatch() {
  return useContext(SystemStateDispatchContext);
}

const db: SystemDB = {
  goals: Promise.resolve(new Goals()),
  matches: Promise.resolve(new Matches()),
  news: Promise.resolve(new News()),
  players: Promise.resolve(new Players()),
  teams: Promise.resolve(new Teams()),
  knockout: Promise.resolve(new KnockoutList()),
  info: Promise.resolve(Info.getEmptyInfo()),
};

const initialState: SystemState = {
  ...db,
};

const SystemStateContext = createContext<SystemState>(initialState);
const SystemStateDispatchContext = createContext<React.Dispatch<SystemStateAction>>(() => null);