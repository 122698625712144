import { DateTime } from "luxon";
import { downloadTextFile } from "../utils/csv";

export class Info {
    start_date: DateTime;
    end_date: DateTime;
    note: string;

    constructor(jsonString: string) {
        const json = JSON.parse(jsonString);
        this.start_date = DateTime.fromISO(json["start_date"]);
        this.end_date = DateTime.fromISO(json["end_date"]);
        this.note = json["note"];
    }

    static getEmptyInfo() {
        const emptyData = {
            "start_date": "0",
            "end_date": "0",
            "note": ""
        }

        return new Info(JSON.stringify(emptyData));
    }

    static parseInfo(file: string): Promise<Info> {
        return new Promise((resolve, reject) => {
            downloadTextFile(file)
            .then((text) => {
                try {
                    const info = new Info(text);
                    resolve(info);
                } catch(err) {
                    reject(err);
                }
            })
            .catch((err) => {
                reject(err);
            });
    
        });
    }
}