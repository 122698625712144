import { Player } from "./player";
import { Team } from "./team";

export class PlayerRanking {
    player: Player;
    team: Team;
    goals: number = 0;

    constructor(
        player: Player,
        team: Team
    ) {
        this.player = player;
        this.team = team;
    }

    getGoals(): number {
        return this.goals;
    }

    static sort(a: PlayerRanking, b: PlayerRanking): number {
        const goalsDiff = b.goals - a.goals;
        const teamSort = Team.sort(a.team, b.team);
        const playerSort = Player.sortByName(a.player, b.player);

        return goalsDiff === 0 ? teamSort === 0 ? playerSort : teamSort : goalsDiff;
    }
}