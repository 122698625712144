import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

type Action = "edit" | "save" | "cancel";

interface FloatingButtonProps {
    action: Action,
    onClick: () => void
}

export default function FloatingButton(props: FloatingButtonProps) {
    const { action, onClick } = props;

    return (
        <>
            <Fab color="primary" aria-label="edit" onClick={onClick}>
            { action === "edit" && <EditIcon /> }
            { action === "save" && <SaveIcon /> }
            { action === "cancel" && <CloseIcon /> }
            </Fab>
        </>
    );
}