import { parseInteger } from "../utils/utils";
import { DBItem, ItemManager } from "./dbItem";

export class KnockoutTeam implements DBItem {
    _id: number;
    placeholder: string;
    team: number;

    constructor(csvArray: string[]) {
        if(csvArray.length !== 3) {
            throw new Error(`Invalid CSV array length for KnockoutTeam: ${csvArray.length}`);
        }

        this._id = parseInteger(csvArray[0]);
        this.placeholder = csvArray[1];
        this.team = parseInteger(csvArray[2]);
    }

    url(): string {
        return `/knockout/${this._id}`;
    }

    id(): string {
        return `knockout-${this._id}`;
    }

    isValid(): boolean {
        return this.placeholder !== "";
    }

    isComplete(): boolean {
        return !Number.isNaN(this.team) 
    }
}

export class KnockoutList implements ItemManager<KnockoutTeam> {
    private items: KnockoutTeam[] = [];

    addItem(line: string[]) {
        const kn = new KnockoutTeam(line);
        this.items.push(kn);
    }
    
    getItems(): KnockoutTeam[] {
        return this.items;
    }

    getById(id: number): KnockoutTeam | undefined {
        return this.getItems().find(item => item._id === id);
    }

    isValid(): boolean {
        return this.getItems().find(item => !item.isValid()) === undefined;
    }

    isComplete(): boolean {
        return this.getItems().find(item => !item.isComplete()) === undefined;
    }
}