import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { Tabs } from '@mui/material';
import RankingGroup from '../components/RankingGroup';
import RankingKnockout from '../components/RankingKnockout';
import RankingGoals from '../components/RankingGoals';
import { useSearchParams } from 'react-router-dom';
import { parsePage } from '../utils/utils';
import { ScrollSnapper } from '../components/ScrollSnapper';

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const numTabs = 3;

export default function Rankings() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onIndexChange(newValue);
  };

  const onIndexChange = (newValue: number) => {
    setValue(newValue);
    searchParams.set("page", `${newValue}`);
    setSearchParams(searchParams);
  }

  React.useEffect(() => {
    const urlPage = parsePage(searchParams.get("page"), numTabs);
    if(urlPage !== undefined) {
      setValue(urlPage);
    } else {
      setValue(0);
    }
  }, [searchParams]);


  return (
    <React.Fragment>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label="Fase a gruppi" {...a11yProps(0)} />
          <Tab label="Eliminazione diretta" {...a11yProps(1)} />
          <Tab label="Goal" {...a11yProps(2)} />
        </Tabs>
    </Box>
    <ScrollSnapper index={value} onIndexChange={onIndexChange} className="ScrollSnapper">
      <RankingGroup value={value} index={0} key={'gruppi'} />
      <RankingKnockout value={value} index={1} key={'eliminazione'} />
      <RankingGoals value={value} index={2} key={'Goal'} />
    </ScrollSnapper>
    </React.Fragment>
  );
}