import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface BackdropProps {
    open: boolean
}

export default function LoadingBackdrop(props: BackdropProps) {
    const { open } = props; 
    return (
        <div>
        <Backdrop
            sx={{ color: '#fff', zIndex: 100000 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        </div>
    );
}