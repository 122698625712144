import { ReactNode } from 'react';
import { responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Theme(props: { children: ReactNode }) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  //TODO choose your colors
  const theme = createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: '#10662F',
      },
      /*
      secondary: {
        main: '#D8A0C0',
      },
      error: {
        main: red['500'],
      },
      background: {
        default: '#FFF9F8',
      } */
    },
  });

  return (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}