import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useYear from '../hooks/useYear';
import SyncIcon from '@mui/icons-material/Sync';
import { reload } from '../utils/utils';

interface TopBarProps {
  menu: boolean
  onMenuClick: () => void
}

export default function TopBar(props: TopBarProps) {
  const year = useYear();

  return (
    <Box sx={{ flexGrow: 1, mb: 2 }}>
      <AppBar position="fixed"  color="primary" enableColorOnDark sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
        <IconButton sx={{ px: 2 }} disabled>
                <img alt="logo" src='/logo512.png' style={{"width": 40}} />
        </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Street Soccer {year}
          </Typography>
          <IconButton color='inherit' sx={{ px: 2 }} onClick={() => reload()}>
                <SyncIcon />
          </IconButton>
          { props.menu && <IconButton edge="start" color="inherit" aria-label="menu" onClick={props.onMenuClick} sx={{ mr: 2 }}>
            <MenuIcon />  
          </IconButton> }
        </Toolbar>
      </AppBar>
    </Box>
  );
}