import Box from '@mui/material/Box';
import Countdown from '../components/Countdown'
import { Alert, Collapse, Container, Grid } from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';
import React from 'react';
import { useSystemState } from '../SystemState';
import NewsList from '../components/NewsList';
import { Info } from '../classes/info';
import InfoGrid from '../components/InfoGrid';
import { isAdmin } from '../utils/api';
import EditNote from '../components/EditNote';
import { DateTime } from 'luxon';
import FloatingButton from '../components/FloatingButton';
import FloatingButtonBar from '../layout/FloatingButtonBar';

export default function MainPage() {
    const [alert, setAlert] = React.useState<boolean>(true);
    const state = useSystemState();
    const [ info, setInfo ] = React.useState<Info>();
    const [ edit, setEdit ] = React.useState<boolean>(false);

    const editEnabled = isAdmin();

    React.useEffect(() => {
        state.info.then(i => {
            setInfo(i);
        }).catch(err => {
            console.error(err);
        })
    }, [state]);

    const infoGrid = info ? <InfoGrid width={300} info={info} /> : <></>

    const countdown = info && info.start_date > DateTime.now() ? (
        <Box textAlign="center" sx={{ p: 2 }}>
            <Countdown
                deadline={info.start_date}
                text="Calcio d'inizio"
            />
        </Box>
    ) : <></>

    return (
        <>
        <Container sx={{ pb: 6 }}>
            <Collapse in={alert && info !== undefined && info.note.length > 0}>
                <Alert variant="outlined" severity="info" color='success' onClose={() => {setAlert(false)}}>
                    <AlertTitle>Info</AlertTitle>
                    { info?.note }
                </Alert>
            </Collapse>
            <Grid sx={{py: 2}}  container spacing={1} justifyContent="center" alignItems="center">
                <Grid item xs>
                <img alt="logo" className="team-img" src='/logo512.png' style={{"width": 200}} />

                </Grid>
                <Grid item xs>
                { infoGrid }

                </Grid>
            </Grid>
            { countdown }
            { info && editEnabled && 
                <Box sx={{ textAlign: "center" }}>
                    { edit && <EditNote note={info.note} onClose={() => setEdit(false)} /> }
                    <FloatingButtonBar>
                        <FloatingButton action="edit" onClick={() => setEdit(true)} />
                    </FloatingButtonBar>
                </Box>
            }
            <NewsList />
        </Container>
        </>
    );
}