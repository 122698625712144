import React from 'react';
import { Outlet } from 'react-router-dom';
import BottomBar from './BottomBar';
import TopBar from './TopBar';
import EditionDrawer from '../components/EditionDrawer';
import { ShowDrawer } from '../config';
import { Toolbar } from '@mui/material';

export default function Layout() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [toolbar,] = React.useState(true);

  return (
      <React.Fragment>
        { toolbar && <TopBar menu={ShowDrawer} onMenuClick={() => { setDrawerOpen(!drawerOpen) }} /> }
        { ShowDrawer && <EditionDrawer toolbar={toolbar} open={drawerOpen} setOpen={setDrawerOpen} /> }
        { toolbar && <Toolbar /> }
        <Outlet />
        <BottomBar />
      </React.Fragment>
  );
}
  