import * as React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useSystemState } from '../SystemState';
import { Match } from '../classes/match';
import { Teams } from '../classes/team';
import MatchListTab from '../components/MatchListTab';
import { dateToString, matchDayString, parsePage } from '../utils/utils';
import Progress from '../components/Progress';
import { DateTime } from 'luxon';
import { ScrollSnapper } from '../components/ScrollSnapper';
import { useSearchParams } from 'react-router-dom';

function groupMatchesByDay(matches: Match[]) {
  const map = new Map<string, Match[]>();

  matches.forEach(m => map.get(m.getMatchday())?.push(m) ?? map.set(m.getMatchday(), [m]));
  return map;
}

function getDayIndex(days: string[]) {
  const today = dateToString(DateTime.now());
  const index = days.findIndex(d => d.localeCompare(today) >= 0);
  return index === -1 ? (days.length - 1) : index;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Matches() {
  let [searchParams, setSearchParams] = useSearchParams();
  const state = useSystemState();
  const [ teams, setTeams ] = React.useState<Teams>();
  const [ groups, setGroups ] = React.useState<Map<string, Match[]>>(new Map());
  const [ days, setDays ] = React.useState<string[]>([]);
  const [value, setValue] = React.useState(0);
  const [ loading, setLoading ] = React.useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onIndexChange(newValue);
  };

  const onIndexChange = (newValue: number) => {
    setValue(newValue);
    searchParams.set("page", `${newValue}`);
    setSearchParams(searchParams);
  }

  React.useEffect(() => {
    async function getMatches() {
        try {
            setLoading(true);
            const teams = await state.teams;
            const matches = (await state.matches).getItems();
            setTeams(teams);

            const groups = groupMatchesByDay(matches);
            const days = Array.from(groups.keys());
            days.sort((a,b) => a.localeCompare(b));
            setGroups(groups);
            setDays(days);

            const urlPage = parsePage(searchParams.get("page"), days.length);
            if(urlPage !== undefined) {
              setValue(urlPage);
            } else {
              setValue(getDayIndex(days));
            }
        } catch(err: any) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    getMatches();
  }, [state, searchParams]);

  return (
    <React.Fragment>
      { loading && <Progress /> }
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange} 
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
        {
          days.map((day, index) => {
            const dayMatches = groups.get(day);
  
            if(dayMatches === undefined) {
              console.error(`Error: no matches found for day ${day}`);
              return (<React.Fragment />);
            }

            const dayString = matchDayString(dayMatches[0].date, true);
  
            return (
              <Tab key={day} label={dayString} {...a11yProps(index)} />
            )
          })
        }
        </Tabs> 
      </Box>
      <ScrollSnapper index={value} onIndexChange={onIndexChange} className="ScrollSnapper">
      {
        days.map((day, index) => {
          const dayMatches = groups.get(day);

          if(dayMatches === undefined) {
            console.error(`Error: no matches found for day ${day}`);
            return (<React.Fragment />);
          }

          if(teams === undefined) {
            console.error(`Error: teams is undefined`);
            return (<React.Fragment />);
          }

          return (
            <MatchListTab value={value} index={index} key={day} matches={dayMatches} teams={teams} />
          )
        })
      }
      </ScrollSnapper>
    </React.Fragment>
  );
}