import { Dialog, Alert, AlertTitle, AlertColor } from "@mui/material"

export type AlertInfo = {
    severity: AlertColor,
    msg: string,
    title?: string
}

export function setErrorAlert(
  msg: string,
  setAlert: React.Dispatch<React.SetStateAction<AlertInfo | undefined>>,
) {
  setAlert({
    severity: "error",
    msg: msg,
    title: "Errore"
  })
}

export function setSuccessAlert(
  msg: string,
  setAlert: React.Dispatch<React.SetStateAction<AlertInfo | undefined>>,
) {
  setAlert({
    severity: "success",
    msg: msg,
    title: "Successo"
  })
}

interface AlertDialogProps {
    alert: AlertInfo,
    onClose: () => void
}

export default function AlertDialog(props: AlertDialogProps) {
    const { alert, onClose } = props;

    return (
        <Dialog
        open={true}
        onClose={onClose}
        >
            <Alert
            severity={alert?.severity}
            onClose={onClose}
            >
            { alert.title && <AlertTitle>{alert.title}</AlertTitle> }
            {alert.msg}
            </Alert>
      </Dialog>
    )
}