import { BottomNavigation, BottomNavigationAction, Paper, styled } from '@mui/material';
import React from 'react';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import HomeIcon from '@mui/icons-material/Home';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import GroupsIcon from '@mui/icons-material/Groups';
import { Link, useLocation } from 'react-router-dom';
import useYear from '../hooks/useYear';

function BottomBar() {
  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const year = useYear();

  React.useEffect(() => {
    if (location.pathname === `/${year}` || location.pathname === '') {
      setValue(0);
    } else if (location.pathname.startsWith(`/${year}/teams`)) {
      setValue(1);
    } else if (location.pathname.startsWith(`/${year}/matches`)) {
      setValue(2);
    } else if (location.pathname.startsWith(`/${year}/rankings`)) {
      setValue(3);
    } else if (location.pathname.startsWith(`/${year}/info`)) {
      setValue(4);
    }
  }, [location,year]);

  return (
    <React.Fragment>
      <Offset />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction component={Link} to={`/${year}`} label="Home"
            icon={<HomeIcon />} />
          <BottomNavigationAction component={Link} to={`/${year}/teams`} label="Squadre"
            icon={<GroupsIcon />} />
          <BottomNavigationAction component={Link} to={`/${year}/matches`} label="Partite"
            icon={<ScoreboardIcon />} />
          <BottomNavigationAction component={Link} to={`/${year}/rankings`} label="Classifiche"
            icon={<EmojiEventsIcon />} />
        </BottomNavigation>
      </Paper>
    </React.Fragment>
  );
}

export default BottomBar;
