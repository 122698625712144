import { parseInteger } from "../utils/utils";
import { DBItem, ItemManager } from "./dbItem";

export class Goal implements DBItem {
    _id: number;
    match: number;
    player: number;
    goals: number;

    constructor(csvArray: string[]) {
        if(csvArray.length !== 4) {
            throw new Error(`Invalid CSV array length for Goal: ${csvArray.length}`);
        }

        const id = parseInteger(csvArray[0]);
        const match = parseInteger(csvArray[1]);
        const player = parseInteger(csvArray[2]);
        const goals = parseInteger(csvArray[3]);

        [id, match, player, goals].forEach((n) => {
            if(Number.isNaN(n)) {
                throw new Error(`Invalid goal row: ${csvArray}`);
            }
        })

        this._id = id;
        this.match = match;
        this.player = player;
        this.goals = goals;
    }

    url(): string {
        return `goals/${this._id}`;
    }

    id(): string {
        return `goal-${this._id}`;
    }

    isComplete(): boolean {
        return !Number.isNaN(this.match) 
            && !Number.isNaN(this.player) 
            && !Number.isNaN(this.goals)
    }
}

export class Goals implements ItemManager<Goal> {
    private items: Goal[] = [];

    addItem(line: string[]) {
        const goal = new Goal(line);
        this.items.push(goal);
    }
    
    getItems(): Goal[] {
        return this.items.filter(goal => goal.isComplete());
    }

    getById(id: number): Goal | undefined {
        return this.getItems().find(item => item._id === id);
    }

    getByPlayer(player: number): Goal[] {
        return this.getItems().filter(item => item.player === player);
    }

    getByMatch(match: number): Goal[] {
        return this.getItems().filter(item => item.match === match);
    }

    getByPlayerAndMatch(player: number, match: number): Goal | undefined {
        return this.getItems().find(item => item.player === player && item.match === match);
    }
}