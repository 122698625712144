import React from "react";
import { SingleNews } from "../classes/news";
import { useSystemState } from "../SystemState";
import NewsCard from "./NewsCard";
import { Grid, Typography } from "@mui/material";

export default function NewsList() {
    const state = useSystemState();
    const [ news, setNews ] = React.useState<SingleNews[]>([]);

    React.useEffect(() => {
        state.news.then(n => {
            setNews(n.getItems());
        }).catch(err => {
            console.error(err);
        })
    }, [state]);

    return (
        <>
            {
                news.length > 0 && 
                <Typography variant="h3" textAlign="center" sx={{py: 4}}>Ultime notizie</Typography>
            }
            <Grid container spacing={3} justifyContent="center">
            {
                news.map(n => (
                    <Grid key={n.id()} item xs="auto">
                        <NewsCard news={n} />
                    </Grid>
                ))
            }
            </Grid>
        </>
    )
}