import { CircularProgress } from "@mui/material";
import React from "react";

export interface ProgressProps {
  inPage?: boolean;
}

function Progress(props: ProgressProps) {
  const { inPage } = props;

  const style: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };
  if (inPage) {
    style.position = 'relative';
    style.width = '100%';
    style.top = '0';
    style.marginTop = '30px';
  }

  return (
    <span style={style}>
      <span style={
        {
          padding: '8px',
          borderRadius: '50%',
          lineHeight: '0',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px',
          zIndex: 1,
        }
      }>
        <CircularProgress color="primary" />
      </span>
    </span>
  );
}

export default Progress;
