import { parseInteger } from "../utils/utils";
import { DBItem, ItemManager } from "./dbItem";

export class Team implements DBItem {
    _id: number;
    name: string;
    shortName: string;
    group: string;
    pic: string;

    constructor(csvArray: string[]) {
        if(csvArray.length !== 5) {
            throw new Error(`Invalid CSV array length for Goal: ${csvArray.length}`);
        }

        const id = parseInteger(csvArray[0]);

        if(Number.isNaN(id)) {
            throw new Error(`Invalid ID for team: ${csvArray}`);
        }

        this._id = id;
        this.name = csvArray[1];
        this.shortName = csvArray[2] === "" ? this.name : csvArray[2];
        this.group = csvArray[3].toLocaleUpperCase();
        this.pic = csvArray[4];
    }

    url(): string {
        return `team/${this._id}`;
    }

    id(): string {
        return `team-${this._id}`;
    }

    initials(): string {
        return `${this.shortName.substring(0,3)}`.toLocaleUpperCase();
    }

    static sort(a: Team, b: Team): number {
        return a.name.localeCompare(b.name);
    }
}

export class Teams implements ItemManager<Team> {
    private items: Team[] = [];

    addItem(line: string[]) {
        const team = new Team(line);
        this.items.push(team);
    }
    
    getItems(): Team[] {
        return this.items;
    }

    getById(id: number): Team | undefined {
        return this.items.find(item => item._id === id);
    }
}