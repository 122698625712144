import { SxProps, TextField } from "@mui/material";
import { Player } from "../classes/player";
import ElementList from "./ElementList";
import { getUrl } from "../utils/utils";
import useYear from "../hooks/useYear";

export type MatchGoals = {
    player: Player,
    goals: number
}

interface GoalListProps {
    goals: MatchGoals[],
    sx?: SxProps,
    edit?: boolean,
    onChange?: (player: number, value: string) => void,
}

function sort(a: MatchGoals, b: MatchGoals) {
    const goalDiff = b.goals - a.goals;
    const nameDiff = a.player.surname.localeCompare(b.player.surname);

    return goalDiff === 0 ? nameDiff : goalDiff;
}

export default function GoalList(props: GoalListProps) {
    const { goals, sx, edit, onChange } = props;
    const year = useYear();

    const goalList = edit ? goals : goals.filter(g => g.goals > 0);
    edit ? goalList.sort((a, b) => a.player.jersey - b.player.jersey) : goalList.sort(sort);

    return (
        <ElementList
            divider
            disablePadding
            elements={goalList.map(g => {
                const title = edit ?
                    `${g.player.jerseyString()} ${g.player.shortName()}`
                    : `${g.player.shortName()}  x${g.goals}`

                const icon = edit ? 
                    <TextField
                        variant="standard"
                        defaultValue={g.goals > 0 ? g.goals : ""}
                        inputProps={{ style: { textAlign: "center" } }}
                        size="medium"
                        type="number"
                        sx={{ width: 40 }}
                        onChange={(e) => {
                            onChange && onChange(g.player._id, e.target.value)
                        }}
                    />
                    : undefined
 
                return {
                    title: title,
                    titleSx: sx,
                    url: edit ? undefined : getUrl(year, g.player),
                    titleVariant: "body2",
                    icon: icon
                }
            })}
        />
    )
}