import { Card, Chip, Grid, Typography } from "@mui/material";
import { KnockoutMatch } from "./KnockoutBrackets";
import { Link } from "react-router-dom";
import { getTeamStyle, getUrl, matchDayString } from "../utils/utils";
import { TeamAvatar, UnknownTeamAvatar } from "./CustomAvatar";
import useYear from "../hooks/useYear";

interface KnockoutMatchCardProps {
    match: KnockoutMatch,
    width: number,
    height: number,
    x: number,
    y: number,
    chip?: string
}

export default function KnockoutMatchCard(props: KnockoutMatchCardProps) {
    const { match, width, height, x, y, chip } = props;
    const year = useYear();

    const team1 = match.team1;
    const team2 = match.team2;
    const url = match.match === undefined ? undefined : getUrl(year, match.match);
    const iconSize = width / 3;
    const textSize = width / 8;
    const chipWidth = iconSize / 2 * 3;
    const chipHeight = chipWidth / 3;

    const tbdStyle = {
        color: "text.secondary"
    }

    const team1TextStyle = team1.team ? match.match ? getTeamStyle(match.match, team1.team._id, true) : {} : tbdStyle;
    const team1ResultStyle = match.match && team1.team ? getTeamStyle(match.match, team1.team._id, false) : tbdStyle;
    const team2TextStyle = team2.team ? match.match ? getTeamStyle(match.match, team2.team._id, true) : {} : tbdStyle;
    const team2ResultStyle = match.match && team2.team ? getTeamStyle(match.match, team2.team._id, false) : tbdStyle;

    const card = (<>
    <Card 
        raised
        sx={{
            position:"absolute",
            left: x,
            top: y,
            width: width,
            height: height,
            borderRadius: 2,
        }}
    >
        <Grid container spacing={0}
            justifyContent="center" 
            alignItems="center"
            textAlign="center"
            sx={{
                p: 1,
                width: width,
                height: height
            }}
        >
            <Grid item xs={5}>
            {
                team1.team ? <TeamAvatar team={team1.team} size={iconSize} /> : <UnknownTeamAvatar size={iconSize} />
            }
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={5}>
            {
                team2.team ? <TeamAvatar team={team2.team} size={iconSize} /> : <UnknownTeamAvatar size={iconSize} />
            }
            </Grid>
            <Grid item xs={5}>
                <Typography variant="caption" sx={team1TextStyle} fontSize={textSize}>
                    {team1.team ? team1.team.initials() : team1.placeholder}
                </Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={5}>
                <Typography variant="caption" sx={team2TextStyle} fontSize={textSize}>
                    {team2.team ? team2.team.initials() : team2.placeholder}
                </Typography>
            </Grid>
            {
                match.match && match.match.isComplete() &&
                <>
                <Grid item xs={5}>
                    <Typography variant="body1" sx={team1ResultStyle} fontSize={textSize}>
                        {match.match.goals1}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1" fontSize={textSize}>
                        -
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="body1" sx={team2ResultStyle} fontSize={textSize}>
                        {match.match.goals2}
                    </Typography>
                </Grid>
                </>
            }
            {
                match.match && !match.match.isComplete() &&
                <>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        {matchDayString(match.match.date, true)}
                    </Typography>
                </Grid>
                </>
            }
            {
                !match.match &&
                <>
                <Grid item xs={12}>
                    <Typography variant="body2" sx={tbdStyle} fontSize={textSize}>
                        TBD
                    </Typography>
                </Grid>
                </>
            }
        </Grid>
    </Card>
    {
        chip && <Chip label={chip} color="success" size="small" sx={{
            position:"absolute",
            left: x + chipWidth / 2,
            top: y + height - chipHeight / 2,
            width: chipWidth,
            height: chipHeight,
            borderRadius: 1,
            fontSize: textSize,
            fontWeight: "bold"
        }} />
    }
    </>)

    return url ? <Link to={url}> { card } </Link> : <>{ card }</>
}