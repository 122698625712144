import { Grid, TextField, Typography } from "@mui/material";
import { Match } from "../classes/match";
import { dayString, matchTimeString } from "../utils/utils";
import { Variant } from "@mui/material/styles/createTypography";

interface MatchResultProps {
    match: Match,
    variant?: Variant,
    colorTime?: boolean,
    showDate?: boolean,
    edit?: boolean,
    onHomeChange?: (value: string) => void,
    onAwayChange?: (value: string) => void,
    onTimeChange?: (value: string) => void,
}

export default function MatchResult(props: MatchResultProps) {
    const {match, variant, showDate, colorTime, onHomeChange, onAwayChange, onTimeChange, edit} = props;
    const result = match.isComplete() ? `${match.goals1} - ${match.goals2}` : matchTimeString(match.date);
    const resultSx = match.isComplete() ? {
        fontWeight: 'bold'
    } : {
        color: colorTime === true ? "text.secondary" : undefined
    }

    return (
        <>
        {
            edit ? 
            <Grid container rowSpacing={2}>
                <Grid item xs={5}>
                    <TextField 
                        variant="standard"
                        defaultValue={match.isComplete() ? match.goals1 : ""}
                        inputProps={{ style: { textAlign: "center" } }}
                        size="medium"
                        type="number"
                        onChange={e => onHomeChange && onHomeChange(e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    -
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        variant="standard"
                        defaultValue={match.isComplete() ? match.goals2 : ""}
                        inputProps={{ style: { textAlign: "center" } }}
                        size="medium"
                        type="number"
                        onChange={e => onAwayChange && onAwayChange(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        defaultValue={matchTimeString(match.date)}
                        inputProps={{ style: { textAlign: "center" } }}
                        size="medium"
                        onChange={e => onTimeChange && onTimeChange(e.target.value)}
                    />
                </Grid>
            </Grid>
            :
            <Typography variant={variant} gutterBottom sx={resultSx}>
                {result}
            </Typography>
        }
        {
            !match.isComplete() && showDate &&
            <Typography textAlign="center" variant="overline" gutterBottom sx={resultSx}>
                {dayString(match.date, false, true)}
            </Typography>
        }
        </>
    )
}