import { Dialog, Card, Typography, TextField, Button } from "@mui/material";
import React from "react";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import AlertDialog, { AlertInfo, setErrorAlert, setSuccessAlert } from "./AlertDialog";
import { loadToken, sendApiRequest } from "../utils/api";
import useYear from "../hooks/useYear";
import LoadingBackdrop from "./LoadingBackdrop";
import { reload } from "../utils/utils";

interface EditNoteProps {
    note: string
    onClose: () => void
}

type JsonData = {
    token: string,
    year: string,
    note: string
  }

function save(
    note: string,
    year: string,
    setAlert: React.Dispatch<React.SetStateAction<AlertInfo | undefined>>,
    setJsonPayload: React.Dispatch<React.SetStateAction<JsonData | undefined>>
) {
    const token = loadToken();
    if(token === null) {
      setErrorAlert(`Non sei admin!`, setAlert);
      return true;
    }

    const data : JsonData = {
        token: token,
        year: year,
        note: note
    }
    
    setJsonPayload(data);
    return true;
}

async function sendUpdate(
    jsonPayload: JsonData,
    setJsonPayload: React.Dispatch<React.SetStateAction<JsonData | undefined>>,
    setAlert: React.Dispatch<React.SetStateAction<AlertInfo | undefined>>,
  ) {
    try {
      const response = await sendApiRequest("street-update-info", JSON.stringify(jsonPayload));
  
      if(response.code === 200) {
        setSuccessAlert("Modifiche salvate.", setAlert);
        setTimeout(() => reload(), 500);
      } else {
        setErrorAlert(`${response.msg}`, setAlert);
      }
    } catch(err) {
        setErrorAlert(`${err}`, setAlert);
    } finally {
        setJsonPayload(undefined);
    }
  }

export default function EditNote(props: EditNoteProps) {
    const year = useYear();
    const [ note, setNote ] = React.useState<string>(props.note);
    const [alert, setAlert] = React.useState<AlertInfo>();
    const [ jsonPayload, setJsonPayload ] = React.useState<JsonData>();

    React.useEffect(() => {
        if(jsonPayload !== undefined) {
          sendUpdate(jsonPayload, setJsonPayload, setAlert);
        }
      }, [jsonPayload, year]);

    return (
    <React.Fragment>
        <LoadingBackdrop open={jsonPayload !== undefined} />
        { alert && <AlertDialog alert={alert} onClose={() => setAlert(undefined)} />}
        <Dialog
            open={true}
            onClose={props.onClose}
            fullWidth
            sx={{ alignContent: "center", textAlign: "center" }}
        >
            <Card sx={{ p: 4 }}>
                <Typography variant="h4" sx={{ pb: 6 }}>Modifica info</Typography>
                <TextField
                    label="Info"
                    multiline
                    rows={3}
                    defaultValue={note}
                    variant="standard"
                    fullWidth
                    sx={{ my: 4 }}
                    onChange={(e) => setNote(e.target.value)}
                />
                <Button 
                    variant="contained" 
                    startIcon={<SaveIcon />}
                    sx={{ my: 4, mx: 2 }} 
                    onClick={() => {
                        save(note, year, setAlert, setJsonPayload)
                    }}
                >
                    Salva
                </Button>
                <Button 
                    variant="contained" 
                    startIcon={<CloseIcon />}
                    sx={{ my: 4, mx: 2 }} 
                    onClick={() => {
                        setAlert(undefined);
                        props.onClose();
                    }}
                >
                    Annulla
                </Button>
            </Card>
        </Dialog>
    </React.Fragment>
    )
}