import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DefaultYear, Years } from '../config';

export default function useYear() {
  const location = useLocation();
  const [ year, setYear ] = useState<string>(DefaultYear);

  useEffect(() => {
    const year = location.pathname.split("/")[1];
    setYear(Years.includes(year) ? year : DefaultYear);
  }, [location]);

  return year;
}