import Papa from "papaparse";

export interface CsvParser {
    addItem(line: string[]): void;
  }

export function downloadTextFile(file: string): Promise<string> {
    return new Promise((resolve, reject) => {
        fetch(file, { cache: "no-cache" })
            .then((response) => {
                if (response.ok) {
                    return response.text();
                } else {
                    reject(response.statusText);
                }
            })
            .then((text) => {
                if(text === undefined) reject("File is undefined");
                else resolve(text);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function readCsv(file: string): Promise<string[][]> {
    return new Promise((resolve, reject) => {
        downloadTextFile(file)
        .then((text) => {
            Papa.parse<string[]>(text, {
                worker: true, // use a web worker so that the page doesn't hang up
                header: false, // don't use the first line as header
                delimiter: ',',
                skipEmptyLines: true,
                complete: (results) => {
                    if(results.errors.length > 0) reject(results.errors);
                    else resolve(results.data);
                }
              });
        })
        .catch((err) => {
            reject(err);
        });

    });
}

export function parseCsv<T extends CsvParser>(
    file:string,
    createWrapper: () => T
): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        readCsv(file)
        .then((lines) => {
          const wrapper = createWrapper();
          lines.shift(); // remove header
          for (const line of lines) {
            try {
                wrapper.addItem(line);
            } catch(err) {
                console.error(`Error parsing ${file} at ${line}`);
                console.error(err);
            }
          }
          resolve(wrapper);
        })
        .catch((err) => {
          reject(err);
        });
    });
}