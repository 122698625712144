import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSystemState } from '../SystemState';
import { Player } from '../classes/player';
import AvatarTitle from '../components/AvatarTitle';
import { Team, Teams } from '../classes/team';
import { Match } from '../classes/match';
import MatchList from '../components/MatchList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PlayerAvatar } from '../components/CustomAvatar';
import Progress from '../components/Progress';
import { isAdmin } from '../utils/api';
import FloatingButton from '../components/FloatingButton';
import FloatingButtonBar from '../layout/FloatingButtonBar';
import EditPlayer from '../components/EditPlayer';
import { parseInteger } from '../utils/utils';

const avatarSize = 60;

export default function PlayerDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const state = useSystemState();
  const [ player, setPlayer ] = React.useState<Player>();
  const [ team, setTeam ] = React.useState<Team>();
  const [ teams, setTeams ] = React.useState<Teams>();
  const [ matches, setMatches ] = React.useState<Match[]>([]);
  const [ goals, setGoals ] = React.useState<number[]>([]);
  const [ loading, setLoading ] = React.useState<boolean>(false);
  const [ edit, setEdit ] = React.useState<boolean>(false);

  const editEnabled = isAdmin();

  React.useEffect(() => {
    async function getInfo() {
      try {
        setLoading(true);
        const players = await state.players;
        const player = players.getById(parseInteger(params.id ?? ""));

        if(player === undefined) {
          return;
        }

        setPlayer(player);
        setTeam((await state.teams).getById(player.team));

        const matches = (await state.matches).getByTeam(player.team);
        const goals = (await state.goals).getByPlayer(player._id);

        setTeams(await state.teams);
        setGoals(matches.map(m => goals.find(g => g.match === m._id)?.goals ?? 0));
        setMatches(matches);
      } catch(err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    getInfo();
  },[state, navigate, params.id]);

  return (
    <React.Fragment>
    { loading && <Progress /> }
    <Container sx={{ p: 1 }}>
    {
      player !== undefined &&
      <AvatarTitle
        avatar={{
          title: player.fullName(),
          subtitle: `${team?.name} ${player.hasJersey() ? `#${player.jerseyString()}` : ""}`,
          avatar: <PlayerAvatar player={player} size={avatarSize} />
        }} 
      />
    }
    {
      matches.length > 0 && teams !== undefined && 
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="team-matches-content"
          >
            <Typography>Partite</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MatchList matches={matches} teams={teams} goals={goals} showDate />
          </AccordionDetails>
        </Accordion>
    }
    {
      player && editEnabled && 
      <>
        { edit && <EditPlayer player={player} onClose={() => setEdit(false)} /> }
        <FloatingButtonBar>
        <FloatingButton action="edit" onClick={() => setEdit(true)} />
        </FloatingButtonBar>
      </>
    }
    </Container>
    </React.Fragment>
  );
}