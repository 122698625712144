import { Avatar, useTheme } from "@mui/material";
import { stringToColor, textColor } from "../utils/avatar";
import React, { ReactNode } from "react";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { Player } from "../classes/player";
import { Team } from "../classes/team";
import { Link } from "react-router-dom";
import { getPicsFolder, getUrl } from "../utils/utils";
import useYear from "../hooks/useYear";

interface CustomAvatarProps {
    name: string,
    src?: string,
    icon?: ReactNode,
    size?: number,
    href?: string,
    gray?: boolean,
    center?: boolean
}

interface TeamAvatarProps {
    team: Team,
    size?: number,
    link?: boolean,
    center?: boolean
}

interface UnknownTeamAvatarProps {
    size?: number,
    center?: boolean
}

interface PlayerAvatarProps {
    player: Player,
    size?: number,
    link?: boolean,
    center?: boolean
}

export function CustomAvatar(props: CustomAvatarProps) {
    const { name, src, icon, size, href, gray, center } = props;
    const theme = useTheme();

    const bgColor = gray ? theme.palette.text.secondary : stringToColor(name);

    const avatar = (
        <Avatar 
            alt={name}
            src={src}
            sx={{
                width: size,
                height: size,
                bgcolor: bgColor,
                color: textColor(bgColor),
                margin: center ? "auto" : undefined
            }}
        >
            {icon}
        </Avatar>
    )

    return href === undefined ?
        <React.Fragment>{avatar}</React.Fragment> 
        : 
        <Link to={href}>{avatar}</Link>
}

export function TeamAvatar(props: TeamAvatarProps) {
    const { team, size, link, center } = props;
    const year = useYear();

    const iconSize = size === undefined ? undefined : (size * 4 / 5); 

    return (
        <CustomAvatar 
            name={team.name}
            icon={<SportsSoccerIcon sx={{ width: iconSize, height: iconSize }} />}
            size={size}
            href={link ? getUrl(year, team) : undefined}
            center={center}
         />
    )
}

export function UnknownTeamAvatar(props: UnknownTeamAvatarProps) {
    const { size, center } = props;

    const iconSize = size === undefined ? undefined : (size * 4 / 5); 

    return (
        <CustomAvatar 
            name={"unknown"}
            icon={<SportsSoccerIcon sx={{width: iconSize, height: iconSize }} />}
            size={size}
            gray
            center={center}
         />
    )
}

export function PlayerAvatar(props: PlayerAvatarProps) {
    const { player, size, link, center } = props;
    const year = useYear();

    return (
        <CustomAvatar
            name={player.fullName()}
            src={`${getPicsFolder(year)}/${player.pic}`}
            icon={player.initials()}
            size={size}
            href={link ? getUrl(year, player) : undefined}
            center={center}
         />
    )
}