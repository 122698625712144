import { Dialog, Typography, TextField, Button, Grid } from "@mui/material";
import React from "react";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import AlertDialog, { AlertInfo, setErrorAlert, setSuccessAlert } from "./AlertDialog";
import { loadToken, sendApiRequest } from "../utils/api";
import useYear from "../hooks/useYear";
import LoadingBackdrop from "./LoadingBackdrop";
import { parseInteger, reload } from "../utils/utils";
import { Player } from "../classes/player";

interface EditPlayerProps {
    player: Player
    onClose: () => void
}

type EditInfo = {
    playerId: number,
    jersey: string,
  }

type JsonData = {
    token: string,
    year: string,
    player_id: number,
    jersey: string
}

function save(
    info: EditInfo,
    year: string,
    setAlert: React.Dispatch<React.SetStateAction<AlertInfo | undefined>>,
    setJsonPayload: React.Dispatch<React.SetStateAction<JsonData | undefined>>
) {
    const token = loadToken();
    if(token === null) {
      setErrorAlert(`Non sei admin!`, setAlert);
      return true;
    }

    if(info.jersey.length > 0 && Number.isNaN(parseInteger(info.jersey))) {
        setErrorAlert(`Numero di maglia non valido`, setAlert);
        return false;
    }

    const data : JsonData = {
        token: token,
        year: year,
        player_id: info.playerId,
        jersey: info.jersey
    }
    
    setJsonPayload(data);
    return true;
}

async function sendUpdate(
    jsonPayload: JsonData,
    setJsonPayload: React.Dispatch<React.SetStateAction<JsonData | undefined>>,
    setAlert: React.Dispatch<React.SetStateAction<AlertInfo | undefined>>,
  ) {
    try {
      const response = await sendApiRequest("street-update-player", JSON.stringify(jsonPayload));
  
      if(response.code === 200) {
        setSuccessAlert("Modifiche salvate.", setAlert);
        setTimeout(() => reload(), 500);
      } else {
        setErrorAlert(`${response.msg}`, setAlert);
      }
    } catch(err) {
        setErrorAlert(`${err}`, setAlert);
    } finally {
        setJsonPayload(undefined);
    }
  }

export default function EditPlayer({ player, onClose }: EditPlayerProps) {
    const year = useYear();
    const [ alert, setAlert ] = React.useState<AlertInfo>();
    const [ jersey, setJersey ] = React.useState<string>(player.hasJersey() ? `${player.jersey}` : "");
    const [ jsonPayload, setJsonPayload ] = React.useState<JsonData>();

    const info : EditInfo = { playerId: player._id, jersey: jersey};

    React.useEffect(() => {
        if(jsonPayload !== undefined) {
          sendUpdate(jsonPayload, setJsonPayload, setAlert);
        }
      }, [jsonPayload, year]);

    return (
    <React.Fragment>
        <LoadingBackdrop open={jsonPayload !== undefined} />
        { alert && <AlertDialog alert={alert} onClose={() => setAlert(undefined)} />}
        <Dialog
            open={true}
            onClose={onClose}
            fullWidth
            sx={{ alignContent: "center", textAlign: "center" }}
        >
            <Grid container sx={{ p: 4 }}>
                <Grid item xs={12}>
                    <Typography variant="h4" sx={{ pb: 6 }}>Modifica giocatore</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Numero di maglia"
                        multiline
                        rows={1}
                        defaultValue={jersey}
                        type="number"
                        variant="standard"
                        sx={{ my: 4 }}
                        onChange={(e) => setJersey(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button 
                        variant="contained" 
                        startIcon={<SaveIcon />}
                        sx={{ my: 4, mx: 2 }} 
                        onClick={() => {
                            save(info, year, setAlert, setJsonPayload)
                        }}
                    >
                        Salva
                    </Button>
                    <Button 
                        variant="contained" 
                        startIcon={<CloseIcon />}
                        sx={{ my: 4, mx: 2 }} 
                        onClick={() => {
                            setAlert(undefined);
                            onClose();
                        }}
                    >
                        Annulla
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    </React.Fragment>
    )
}