import { Grid, Typography } from "@mui/material"
import { ReactNode } from "react"

export type ElementAvatar = {
    title: string,
    subtitle?: string,
    avatar: ReactNode,
    avatarSrc?: string
}

interface AvatarTitleProps {
    avatar: ElementAvatar
}

export default function AvatarTitle(props: AvatarTitleProps) {
    const avatar = props.avatar;

    return (
    <Grid container spacing={2}
        justifyContent="center" 
        alignItems="center"
        sx={{p: 4}}
      >
        <Grid item xs="auto" textAlign="center">
          {avatar.avatar}
        </Grid>
        <Grid item xs textAlign="left">
          <Typography variant="h5">
            {avatar.title}
          </Typography>
          <Typography variant="subtitle2" sx={{color:"text.secondary"}}>
            {avatar.subtitle}
          </Typography>
        </Grid>
      </Grid>
    )
}