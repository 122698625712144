import { Box, Button, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function NoPage() {
  return (
    <Container  sx={{ my: 4 }}>
      <Box textAlign='center' alignItems='center'>
        <Typography variant="h1" component="div">
          404
        </Typography>
        <Typography variant="h2" component="div">
          Pagina non trovata
        </Typography>
        <Link to="/">
          <Button variant="contained" sx={{m: 5}} size='large'>Home</Button>
        </Link>
      </Box>
    </Container>
  );
}

export default NoPage;
