import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Drawer, ListSubheader, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Years } from '../config';
import useYear from '../hooks/useYear';

interface EditionDrawerProps {
    toolbar: boolean
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function EditionDrawer(props: EditionDrawerProps) {
  const navigate = useNavigate();
  const year = useYear();

  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      props.setOpen(open);
    };

  const theme = useTheme();

  return (
        <React.Fragment>
          <Drawer
            anchor='right'
            open={props.open}
            onClose={toggleDrawer(false)}
            sx={{
                width: 250,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: 250, boxSizing: 'border-box' },
            }}
            PaperProps={{
              sx: {
                bgcolor: theme.palette.background.paper,
              }
            }}
          >
            { props.toolbar && <Toolbar /> }
            <Box
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            sx={{ overflow: 'auto' }}
            >
              <List>
                {Years.map((text) => {
                  const url = `/${text}`
                  const title = `Street Soccer ${text}`

                  return (
                  <React.Fragment key={text}>
                    <ListSubheader>{text}</ListSubheader>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => navigate(url)}>
                        <ListItemText primary={title} />
                      </ListItemButton>
                    </ListItem>
                  </React.Fragment>
                )
              })}
              </List>
              <List sx={{ position:"absolute", bottom: 0, width: "100%" }} >
                    <ListItem disablePadding>
                    <ListItemButton
                        sx={{ height: "50px", bgcolor: theme.palette.background.paper }} 
                        onClick={() => navigate(`/${year}/admin`)}>
                      </ListItemButton>
                    </ListItem>
                  </List>
            </Box>
          </Drawer>
        </React.Fragment>
  );
}